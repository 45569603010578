<div class="flex flex-row flex-wrap">

    <label class="w-full sm:w-full md:w-1/4 lg:w-1/4 xl:w-1/4 p-2">
        {{ label }}
        <span *ngIf="isRequired" class="required-astr">*</span>
    </label>
    <div class="w-full sm:w-full md:w-3/4 lg:w-3/4 xl:w-3/4">
        <dx-drop-down-box #dropdownRef 
        [rtlEnabled]="isRtlEnabled$ | async"
        [(value)]="value"
        valueExpr="Id"
        [deferRendering]="false" 
        [displayExpr]="displayExpr" 
        [showClearButton]="!isRequired"
        [dataSource]="StatusList$ | async"
        [disabled]="disabled" 
        [readOnly]="readOnly"
        [dropDownOptions]="{ fullScreen: true }" 
        (onSelectionChanged)="onDropDownSelectionChanged($event)"
        (onValueChanged)="onStatusValueChanged($event)">

            <div *dxTemplate="let data of 'content'">
                
                <dx-button
                    icon="remove"
                    [hint]="'COMMON._Close_' | translate"
                    (onClick)="dropdownRef.instance.close()"
                ></dx-button>
                
                <dx-data-grid #gridRef *ngIf="!disabled" 
                    [rtlEnabled]="isRtlEnabled$ | async"
                    [dataSource]="StatusList$ | async"
                    keyExpr="Id" 
                    [(selectedRowKeys)]="value"
                    [filterRow]="{ visible: true, showOperationChooser: false }"
                    [paging]="{ enabled: true, pageSize: 10 }" 
                    [hoverStateEnabled]="true"
                    [scrolling]="{ mode: 'virtual', rowRenderingMode: 'virtual' }" 
                    [showBorders]="false" 
                    height="100%"
                    [columnHidingEnabled]="true"
                    (onSelectionChanged)="onGridSelectionChanged($event)"
                    >
                    <dxo-selection selectAllMode="allPages" showCheckBoxesMode="always" [mode]="selection">
                    </dxo-selection>

                    <dxi-column dataField="Name" [caption]="'CORRESPONDENCES._Status_' | translate"></dxi-column>

                </dx-data-grid>
            </div>
        </dx-drop-down-box>
    </div>
</div>
