import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-attachment-popup',
  templateUrl: './attachment-popup.component.html',
})
export class AttachmentPopupComponent {
  @Input() isRtlEnabled: boolean;
  @Input() isPopupVisible: boolean;
  attachmentSrc = '';

  async presentModal(url) {
    const modal = await this.attachmentPopupComponent.create({
      component: AttachmentPopupComponent,
      cssClass: 'p-5 text-center',
      backdropDismiss: true,
      componentProps: {
        attachmentSrc: url
      }
    });
    return await modal.present();
  }

  public dismiss() {
    this.attachmentPopupComponent.dismiss();
  }

  constructor(public attachmentPopupComponent: ModalController) {}
}
