import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { StorageService } from 'src/app/shared/services/storage.service';

/**
 * @class GlobalLanguage Service
 * @author muhammed.mousa@hadafsolutions.net (Muhammed Moussa)
 * @package personnel
 * @version 1.0.0
 */

@Injectable({
  providedIn: 'root'
})

export class GlobalLanguageService {
  private LANGUAGE$ = new BehaviorSubject<string>(null);
  getLang = (): Observable<any> =>  this.LANGUAGE$.asObservable();
  constructor(
    storageService: StorageService
  ) {
    const Lang = storageService.getItem('SelectedLang') || 'ar-EG';
    this.LANGUAGE$.next(Lang);
  }
}