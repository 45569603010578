import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { Notifications, UserDevice } from 'src/app/models';
import { configStorageGetter } from '../utilities/config-storage-getter';
import { ErrorHandlerService } from './error-handler.service';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root'
})

export class NotifactionsService {
  currentUserDevice$ = new ReplaySubject<UserDevice>(null);
  Notification: Notifications[];
  apiUrl;

  addCurrentDeviceId = (body): void => {
    this.getApiUrl();
    this.showLoading();
    this.http.post<UserDevice>(`${this.apiUrl}/User/AddDevice`, body).subscribe(() => {
      this.hideLoading();
    }, error => {
      this.hideLoading();
      this.errorHandlerService.handleError(error);
    });
  }

  removeCurrentDeviceId = (id): void => {
    this.getApiUrl();
    this.showLoading();
    this.http.post(`${this.apiUrl}/User/RemoveDevice`, id).subscribe(() => {
      this.hideLoading();
    }, error => {
      this.hideLoading();
      this.errorHandlerService.handleError(error);
    });
  }

  getNotificationCount = () => this.http.get<number>(`${this.apiUrl}/UserNotification/GetNotificationCount`);

  markAllNotificationAsRead = () => this.http.put(`${this.apiUrl}/UserNotification/MarkAllNotificationAsRead`, null);

  getNotification = (query?: any) => {
    this.http.get<Notifications[]>(`${this.apiUrl}/UserNotification/GetNotifications?${query}`).subscribe(res => {
      this.Notification = res;
    });
  }

  handleError = (error: any) => this.errorHandlerService.handleError(error);

  showLoading = (): void => this.loadingService.showLoading();
  hideLoading = (): void => this.loadingService.hideLoading();

  // tslint:disable: no-console
  private getApiUrl = async () => {
    try {
      this.apiUrl = configStorageGetter()?.apiUrls?.issuerApiUri;
      console.info('API DONE WITH CONFIG FILE...');
    } catch (error) {
      console.info('FAILED TO GET CONFIG FILE FOR API...');
    }
  }

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService,
    private loadingService: LoadingService
  ) {
      this.getApiUrl();
  }
}
