import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Market } from '@ionic-native/market/ngx';
import { ModalController } from '@ionic/angular'; 

@Component({
  selector: 'app-version-code-popup',
  template: `
  <ion-content class="modal-bg rounded bg-white p-5">
    <img src="/assets/dist/img/Update-amico.png" alt="save-img" class="m-auto my-3 w-1/3">
    <h1 class="text-blue-500 text-2xl" translate="COMMON._OutDatedApp_"></h1>
    <ion-button expand="block" (click)="openMarket()" class="shadow-lg w-2/3 mx-auto my-3 h-8 text-xl">
      {{'COMMON._Update_' | translate}}
    <ion-ripple-effect type="unbounded"></ion-ripple-effect>
  </ion-button>
  </ion-content>`,
  styleUrls: ['./version-code-popup.component.scss'],
})
export class VersionCodePopupComponent  {
  @Input() isRtlEnabled: boolean;
  @Output() whenPopupConfirm: EventEmitter<any> = new EventEmitter<any>();

  async presentModal() {
    const modal = await this.modalControllerAppVersion.create({
      component: VersionCodePopupComponent,
      cssClass: 'p-5 text-center error-popup',
      backdropDismiss: false
    });
    return await modal.present();
  }

  public dismiss() {
    this.modalControllerAppVersion.dismiss();
  }

  openMarket = () => {
    this.dismiss();
    this.market.open('hadafsolutions.ess');
  }
  constructor(public modalControllerAppVersion: ModalController, private market: Market) {}

}
