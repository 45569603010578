import { DOCUMENT } from '@angular/common';
import { Component, Input, Renderer2, Inject, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SubSink } from 'subsink';
import { CompanyAppSettingsClient } from '../../payroll-client';
import { ErrorHandlerService } from '../../services/error-handler.service';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnInit, OnChanges {
  
  @Input() Latitude: number;
  @Input() Longitude: number;
  
  private subs = new SubSink();

  map: google.maps.Map;
  marker: google.maps.Marker;

  defaultZoom = 13;

  ngOnInit(){
    this.renderMapScript();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateMarker();
  }

  initializeMap() {
    this.drawMap();
    this.updateMarker();
  }

  drawMap() {
    if(this.map) {return;}

    this.map = new google.maps.Map(
      document.getElementById("map-canvas"),
      {
        center: new google.maps.LatLng(this.Latitude, this.Longitude),
        zoom: this.defaultZoom,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      }
    );
  }

  updateMarker(){

    if(!this.map)
      return;

    if(this.marker)
      this.marker.setMap(null);

    this.marker = new google.maps.Marker({
      position: new google.maps.LatLng(this.Latitude, this.Longitude)
    });
  
    this.marker.setMap(this.map);
  }

  renderMapScript(){

    this.subs.sink = this.companyAppSettingsClient.getCompanySettings().subscribe(res => {
      const googleApiKey = res?.GoogleApiKey;
      if(!googleApiKey)
      {
        const err = this.translate.instant('SETTINGS._GoogleApiKeyError_');
        this.error.handleError({ statusText: err });
        return;
      }      
      
      const script = this._renderer2.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=geometry,drawing&callback=initializeMap`;
      script.async = true;
      this._renderer2.appendChild(this._document.head, script);
    },
    err => this.error.handleError(err));
  }

  constructor
  (
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private companyAppSettingsClient: CompanyAppSettingsClient,
    private error: ErrorHandlerService,
    private translate: TranslateService
  )
  {
    this.initializeMap = this.initializeMap.bind(this);
    window['initializeMap'] = this.initializeMap;
  }

}
