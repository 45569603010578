import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Employee } from 'src/app/models';
import { NotifactionComponent } from 'src/app/shared/components/notifaction/notifaction.component';
import { LicenseService } from 'src/app/shared/services/license.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { UserDataService } from 'src/app/shared/services/user-data.service';
import { SubSink } from 'subsink';
import { AuthService } from '../../services/auth.service';
import { GlobalLanguageService } from '../../services/global-language.service';


@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.sass']
})

export class NavComponent implements OnInit, OnDestroy {
  @ViewChild('notifactionRef') notifactionRef: NotifactionComponent;

  isRtlEnabled: boolean;
  profileSettings: any;
  currentUser$: Observable<Employee>;
  language: any[];
  darkModeOn = false;
  isUserActionsListVisible = false;

  private subs = new SubSink();

  ngOnInit(): void {
    this.getLanguageToRTL();
    this.getCurrentUserData();
    this.setLanguageList();
    this.getDarkModeState();
  }

  switchLanguage = (e) => {
    this.storageService.setItem('SelectedLang', e.itemData.value);
    location.reload();
  }

  setLanguageList = () => {
    this.language =  [
      { value: 'ar-EG', name: 'العربية' },
      { value: 'en-US', name: 'English' },
    ];
  }

  getLanguageToRTL = (): void => {
    this.subs.sink = this.languageService.getLang().subscribe(lang => {
      if (lang === null || lang === 'ar-EG') {
        this.isRtlEnabled = true;
        this.translate.use('ar');
        document.querySelector('body').classList.add('dx-rtl');
      } else {
        this.isRtlEnabled = false;
        this.translate.use('en');
        document.querySelector('body').classList.remove('dx-rtl');
      }
    });
  }

  getCurrentUserData = (): any => this.currentUser$ = this.userDataService.currentUser$;

  getUserName = (): string => {
    let arrName;
    this.subs.sink = this.currentUser$.subscribe(user => {
      if (user) {
        if (this.isRtlEnabled) {
          const temp = user.EmployeeName.split(' ');
          arrName = temp[0] && temp[1] ? `${temp[0]} ${temp[1]}` : `${temp[0]} `;
          return;
        } else {
          const temp = user.EmployeeNameSecondLanguage.split(' ');
          arrName = temp[0] && temp[1] ? `${temp[0]} ${temp[1]}` : `${temp[0]} `;
          return;
        }
      }
    });
    return arrName;
  }

  logOut = () => this.authService.logout();

  switchTheme = (e) => {
    if (e === 'dark') {
      localStorage.setItem('darkModeOn', 'true');
      this.isDarkModeOn();
      this.getDarkModeState();
    }
    if (e === 'light') {
      localStorage.setItem('darkModeOn', 'false');
      this.isDarkModeOff();
      this.getDarkModeState();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  isDarkModeOn = () => this.darkModeOn = true;
  isDarkModeOff = () => this.darkModeOn = false;

  getDarkModeState = () => {
    if (localStorage.darkModeOn === 'true') {
      document.querySelector('html').classList.add('dark');
      this.isDarkModeOn();
    } else {
      document.querySelector('html').classList.remove('dark');
      this.isDarkModeOff();
    }
  }

  clearstoredLicenseUrls() {
    this.licenseService.clearstoredLicenseUrls();
    this.logOut();
  }

  showUserActionsList = () => {
    this.isUserActionsListVisible = !this.isUserActionsListVisible;
    if (this.isUserActionsListVisible) {
      document.getElementById('main-cont').classList.add('light-opacity');
    }
    if (!this.isUserActionsListVisible) {
      document.getElementById('main-cont').classList.remove('light-opacity');
    }
  }

  hideUserActionsList = () => {
    this.isUserActionsListVisible = false;
    document.getElementById('main-cont').classList.remove('light-opacity');
  }

  hideNotificationList = () => this.notifactionRef.hideNotification();
  controlNotification = () => this.notifactionRef.controlNotification();

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private languageService: GlobalLanguageService,
    private userDataService: UserDataService,
    private storageService: StorageService,
    private licenseService: LicenseService
  ) {}
}
