<div class="login-form">
    <div>
        <span> {{'COMMON._CreateNewPassword_' | translate}} </span>
        <img src="/assets/dist/img/logo.png" alt="Logo">
    </div>
    <form [formGroup]="NewPasswordForm">
        <div class="flex centrize">
            <label>{{'COMMON._OldPassword_' | translate}}</label>
            <dx-text-box
                [stylingMode]="stylingMode"
                formControlName="OldPassword"
                [elementAttr]="{ class: 'OldPassNameBox' }"
                mode="password"
            ></dx-text-box>
        </div>

        <div class="flex centrize">
            <label>{{'COMMON._NewPassword_' | translate}}</label>
            <dx-text-box
                [stylingMode]="stylingMode"
                formControlName="NewPassword"
                [elementAttr]="{ class: 'NewPassNameBox' }"
                mode="password"
            ></dx-text-box>
        </div>
        <div class="flex centrize">
            <label style="width: 130px;">{{'COMMON._NewPasswordConfirm_' | translate}}</label>
            <dx-text-box
                [stylingMode]="stylingMode"
                formControlName="NewPasswordConfirm"
                [elementAttr]="{ class: 'ConfirmNewPassNameBox' }"
                mode="password"
            ></dx-text-box>
        </div>
        <div class="flex centrize">
            <dx-button
                [text]="'COMMON._ResetPassword_' | translate"
                style="background: #0443c1; height: 50px; color:#fff;"
                (onClick)="onSubmit(NewPasswordForm.value)">
            </dx-button>
        </div>

        
    </form>
</div>
