import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

/**
 * @class Error Handler Service
 * @author muhammed.mousa@hadafsolutions.net (Muhammed Moussa)
 * @package personnel
 * @version 1.0.0
 */

@Injectable({ providedIn: 'root' })

export class ErrorHandlerService {
  error$ = new BehaviorSubject<object>(null);

  constructor(
    private translate: TranslateService,
    private router: Router
  ) { }

  getErrors = (): any => this.error$.asObservable();
  setErrors = (error: object): void => this.error$.next(error);

  handleError = 
  (error: any) => {
    if (!error) { return; }

    const errMsg = error?.statusText ? error?.statusText : this.translate.instant('COMMON._Error_');
    const CORS = this.translate.instant('COMMON._CORS_');
    const UnAuthError = this.translate.instant('COMMON._UnAuthError_');
    if (error?.status === 0) {
      this.setErrors({CORS});
      this.timeOutToClearError();
      return;
    }
    if (error?.status === 401) {
      this.setErrors({UnAuthError});
      return this.router.navigateByUrl('/signin');
      this.timeOutToClearError();
    }
    if (error?.status !== 400) {
      this.setErrors({errMsg});
      this.timeOutToClearError();
      return;
    }
    if (error === null) {
      this.setErrors({errMsg});
      this.timeOutToClearError();
      return;
     } else {
      this.setErrors(error?.error?.errors || error?.errors);
      this.timeOutToClearError();
    }
  }

  isValidEmail = (email: string) => {
    // tslint:disable-next-line: max-line-length
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }


  timeOutToClearError = (): any => setTimeout(() => this.error$.next(null) , 3000);
}
