<ion-content class="modal-bg rounded bg-white p-5">
  <iframe
    [src]="attachmentSrc | safe"
    width="100%"
    height="535"
    frameborder="0"
    scrolling="yes"
    id="ATMReportsIframe"
    webkitallowfullscreen
    mozallowfullscreen
    allowfullscreen
    > 
  </iframe>
</ion-content>