import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DxTextBoxComponent } from 'devextreme-angular';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DxDropDownBoxComponent } from 'devextreme-angular/ui/drop-down-box';
import { Observable } from 'rxjs';
import { GlobalLanguageService } from 'src/app/core/services/global-language.service';
import { SubSink } from 'subsink';
import { BranchClient, BranchForListDto, DepartmentClient, DepartmentForListDto, EmployeeClient, EmployeeForListDto, JobClient, JobForListDto, ProjectClient, ProjectForListDto } from '../../payroll-client';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-shared-employee-list',
  templateUrl: './shared-employee-list.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: SharedEmployeeListComponent
    }
  ]
})

export class SharedEmployeeListComponent implements ControlValueAccessor,  OnInit, OnDestroy {
  
  @ViewChild('gridRef', { static: false }) gridRef: DxDataGridComponent;
  @ViewChild('dropdownRef', { static: false }) dropdownRef: DxDropDownBoxComponent;
  @ViewChild('textRef', { static: false }) textRef: DxTextBoxComponent;

  onChange = (quantity) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;
  
  @Input() selection: string;
  @Input() isRequired: boolean;
  @Input() readOnly: boolean;
  @Input() label: string;
  @Input() staffEmployeesOnly: boolean;
  
  value: number[] = [];
  EmployeeNames: string = '';
  
  isRtlEnabled$: Observable<boolean>;
  Employees$: Observable<EmployeeForListDto[]>;
  Branch: BranchForListDto[] = [];
  Department: DepartmentForListDto[] = [];
  Project: ProjectForListDto[] = [];
  Job: JobForListDto[] = [];

  private subs = new SubSink();

  ngOnInit() {
    this.setRtl();
    this.getDepartment();
    this.getEmployees();
    this.getJob();
    this.getProject();
    this.getBranch();    
  }

  onDropDownValueChanged(e){
    this.markAsTouched();
    this.onChange(this.value);
    if(!e.value)
    {
      this.value = [];
      this.EmployeeNames = '';
    }
  }

  onGridSelectionChanged(e){
    if(this.dropdownRef.opened){
      this.markAsTouched();
      this.handleDropDownClose();
      this.EmployeeNames = e.selectedRowsData.map(x => x.EmployeeName).toString();
      this.value = e.selectedRowKeys;
      this.onChange(this.value);
    }
  }

  getBranch = (): void => {
    
    this.subs.sink = this.branchClient.getBranchList().subscribe(res => {
      this.Branch = res;
      this.hideLoading();
    }, err => {
      this.hideLoading();
      this.handleError(err);
    });
  }

  getDepartment = (): void => {
    
    this.subs.sink = this.departmentClient.getDepartmentList().subscribe(res => {
      this.Department = res;
      this.hideLoading();
    }, err => {
      this.hideLoading();
      this.handleError(err);
    });
  }

  getProject = (): void => {
    
    this.subs.sink = this.projectClient.getAllActiveProjectsList().subscribe(res => {
      this.Project = res;
      this.hideLoading();
    }, err => {
      this.hideLoading();
      this.handleError(err);
    });
  }

  getJob = (): void => {
    
    this.subs.sink = this.jobClient.getJobList().subscribe(res => {
      this.Job = res;
      this.hideLoading();
    }, err => {
      this.hideLoading();
      this.handleError(err);
    });
  }

  getEmployees = (): void => {
    this.showLoading();

    if(this.staffEmployeesOnly){
      this.Employees$ = this.employeeClient.getStaffEmployeeList(true);
    }
    else{
      this.Employees$ = this.employeeClient.getEmployeeList(true);
    }
  }

  handleDropDownClose = (): void => {
    if (this.selection === 'single') {
      this.dropdownRef.instance.close();
    }
  }
  
  showLoading = (): void => this.loadingHandlerService.showLoading();
  hideLoading = (): void => this.loadingHandlerService.hideLoading();

  handleError = (error: any) => this.errorHandlerService.handleError(error);

  setRtl(){
    this.isRtlEnabled$ = this.language.getLang();
  }

  ngOnDestroy() { this.subs.unsubscribe(); }

  constructor(
    private loadingHandlerService: LoadingService,
    private errorHandlerService: ErrorHandlerService,
    private employeeClient: EmployeeClient,
    private branchClient: BranchClient,
    private departmentClient: DepartmentClient,
    private projectClient: ProjectClient,
    private jobClient: JobClient,
    private language: GlobalLanguageService
  ) 
  { 
  }
  
  writeValue(value: number[]): void {
    this.value = value;
    this.subs.sink = this.Employees$.subscribe(res => 
      this.EmployeeNames = res.filter(emp => this.value.includes(emp.EmployeeId))
        .map(emp => emp.EmployeeName).toString());
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}

