import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';
import { map } from 'rxjs/operators';
import { LoadingService } from '../services/loading.service';

/**
 * @class Add Authorization Header Interceptor
 * @author muhammed.mousa@hadafsolutions.net (Muhammed Moussa)
 * @package personnel
 * @version 1.0.0
 */

@Injectable({ providedIn: 'root' })
export class AddAuthorizationHeaderInterceptor implements HttpInterceptor 
{
  constructor(private storageService: StorageService, private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> 
  {
    const token = this.storageService.getItem('access_token');
    const loading = this.injector.get(LoadingService);
    if (req.url.includes('//connect')) {
      req = req.clone({url: req.url.replace('//connect', '/')});
    }
    if (token) {
      req = req.clone(
          {
              setHeaders: {
                  Authorization: 'Bearer ' + token
              }
          });
        
    }

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {loading.hideLoading();}
        return event;
      }),
    );
  }
}
