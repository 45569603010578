import { Injectable, Injector } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * @class I18Loader Service
 * @author muhammed.mousa@hadafsolutions.net (Muhammed Moussa)
 * @package personnel
 * @version 1.0.0
 */

@Injectable({
  providedIn: 'root'
})

export class I18LoaderService implements TranslateLoader {

  getTranslation(lang: string): Observable<any> {
    const http = this.inej.get(HttpClient);
    return http.get(`assets/i18n/${lang}.json`);
  }

  constructor(private inej: Injector) { }
}
