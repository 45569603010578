import { Component, Input } from '@angular/core';
import { ModalController} from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { AuthService } from 'src/app/core/services/auth.service';

import { LicenseService } from '../../services/license.service';
import { LoadingService } from '../../services/loading.service';

/**
 * @Page LicenseModal Page
 * @author muhammed.mousa@hadafsolutions.net (Muhammed Moussa)
 * @package ESS
 * @version 1.0.0
 */

@Component({
  selector: 'app-license-modal',
  templateUrl: './license-modal.page.html',
  styleUrls: ['./license-modal.page.scss'],
})
export class LicenseModalPage {
  @Input() companyCode: string;

  getLicenseByCode() {
    const {companyCode} = this;
    if (companyCode) {
      this.loadingService.showLoading();
      this.licenseService.getLicensUrlseByCode(companyCode)
      .then(res => {
        this.loadingService.hideLoading();
        this.licenseService.confirmLicenseExist();
        this.licenseService.storeLicenseUrls(res);
        this.authService.confgAuthPasswordFlowConfigForMobile(res, window.localStorage);
        this.dismiss();
      })
      .catch(err => {
        this.loadingService.hideLoading();
        this.presentToast(err);
      });
    }
  }

  private dismiss() {
    this.modalCtrl.dismiss();
  }

  async presentToast(err: any) {
    const toast = await this.toastController.create({
      message: err,
      duration: 2000,
      color: 'danger'
    });
    toast.present();
  }

  constructor(
    private modalCtrl: ModalController,
    private licenseService: LicenseService,
    private toastController: ToastController,
    private loadingService: LoadingService,
    private authService: AuthService
  ) {}
}
