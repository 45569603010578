
<label>
  {{label}} <span *ngIf="isRequired" class="required-astr">*</span>
</label>

<dx-lookup
  [disabled]="isDisabled"
  [dataSource]="dataSource$ | async"
  [rtlEnabled]="isRtlEnabled"
  [value]="getValue"
  valueExpr="ProjectId"
  displayExpr="ProjectName"
  [closeOnOutsideClick]="true"
  stylingMode="filled"
  placeholder=" "
  [searchEnabled]="true"
  [showClearButton]="true"
  [showCancelButton]="false"
  [clearButtonText]="'COMMON._Clear_' | translate"
  [searchPlaceholder]="'COMMON._Search_' | translate"
  (onOpened)="onOpened($event)"
  (onClosed)="onClosed($event)"
  (onValueChanged)="onValueChanged($event)"
>
  <dx-validator *ngIf="isRequired">
      <dxi-validation-rule 
          type="required" 
          [message]="'COMMON._Required_'| translate"
      ></dxi-validation-rule>
  </dx-validator>
</dx-lookup>