import { Component,
         Input,
         Output,
         EventEmitter } from '@angular/core';

@Component({
  selector: 'app-confirm-popup',
  template: `
  <dx-popup
    [visible]="isConfirmationPopupVisible"
    shading="true"
    shadingColor="rgba(0, 0, 0, 0.2)"
    [rtlEnabled]="isRtlEnabled"
    closeOnBackButton="false"
    closeOnOutsideClick="false"
    width="400"
    height="auto"
    [showTitle]="false"
    resizeEnabled="true"
    (onHiding)="onHiding($event)"
    dragEnabled="false">
    <div *dxTemplate="let data of 'content'" class="text-center">
      <img src="/assets/dist/img/close.jpg" alt="warning-img" class="m-auto mb-10">
      <h1 [translate]="bodyMsg" class="my-5 warning-text"></h1>
      <p class="warning-text mb-5 text-lg">{{ ConfirmPopupMsg }}</p>
      <div class="flex flex-row flex-wrap centrize">
        <dx-button
          class="warning-btn m-5"
          [rtlEnabled]="isRtlEnabled"
          [text]="'COMMON._Yes_' | translate"
          (click)="clicked(true)"
        ></dx-button>

        <dx-button
          class="warning-btn m-5"
          [rtlEnabled]="isRtlEnabled"
          [text]="'COMMON._No_' | translate"
          (click)="clicked(false)"
        ></dx-button>
      </div>
    </div>
  </dx-popup>
  `
})

export class ConfirmPopupComponent {
  @Input() isConfirmationPopupVisible: boolean;
  @Input() isRtlEnabled: boolean;
  @Input() ConfirmPopupMsg: string;
  @Input() bodyMsg: string = 'COMMON._ConfirmDelete_';

  @Output() whenPopupConfirm: EventEmitter<any> = new EventEmitter<any>();
  @Output() whenPopupHide: EventEmitter<any> = new EventEmitter<any>();

  onHiding = e => this.whenPopupHide.emit(e);
  clicked = (value: boolean) => this.whenPopupConfirm.emit({value});
}
