import { Injectable } from '@angular/core';
import { HttpInterceptor,
         HttpHandler,
         HttpRequest,
         HttpEvent} from '@angular/common/http';

import { Observable } from 'rxjs';
import * as moment from 'moment';

@Injectable()

export class DateHandlerInterceptor implements HttpInterceptor {
    intercept (request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const hasQueryString = request.url.indexOf('?') != -1;
        let queryString = request.url.substring(request.url.indexOf('?') + 1);
        
        if(hasQueryString){
            queryString = this.handleTimeZoneForQuery(queryString);
        }
        
        const newRequest = request.clone({ 
            url: hasQueryString ? `${request.url.split("?")[0]}?${queryString}` : request.url,
            body: this.handleTimeZoneForBody(this.convertBodyToObject(request.body))
        });
        return next.handle(newRequest);
    }

    private convertBodyToObject(body: any)
    {
        return body && typeof body === 'string' && body.includes('{') ? JSON.parse(decodeURIComponent(body)): body;
    }

    private handleTimeZoneForBody(body) {
        if (body === null || body === undefined) {
            return body;
        }
    
        if (typeof body !== 'object') {
            return body;
        }
    
        for (const key of Object.keys(body)) {
            const value = body[key];
            if (this.isDate(value)) {
                body[key] = moment(value).format('MM/DD/YYYY, hh:mm:ss A');
            } else if (typeof value === 'object') {
                this.handleTimeZoneForBody(value);
            }
        }
        return body;
    }

    private handleTimeZoneForQuery(queryString: string): string
    {
        queryString.split("&").forEach(keyValPair => 
            {
                const value = decodeURIComponent(keyValPair.split("=")[1]);
                if(this.isDate(value))
                    queryString = queryString.replace(encodeURIComponent(value), moment(value).format('MM/DD/YYYY, hh:mm:ss A'));
        });
        return queryString;
    }

    private isDate(value: any): boolean{
        return moment(value, moment.ISO_8601, true).isValid();
    }
}
