import { Component, EventEmitter, Input, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DxValidatorComponent } from 'devextreme-angular/ui/validator';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormState } from 'src/app/models';
import { ProjectClient, ProjectForListDto } from '../../payroll-client';

@Component({
  selector: 'app-projects-lookup',
  templateUrl: './projects-lookup.component.html',
  styleUrls: ['./projects-lookup.component.scss'],
})
export class ProjectsLookupComponent {

  @ViewChildren(DxValidatorComponent) validatorViewChildren: QueryList<DxValidatorComponent>;

  @Input() control: FormControl;
  @Input() value: number | null;
  @Input() label: string = "PROJECTATTENDANCE._Project_";
  @Input() disabled: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() isRtlEnabled: boolean = false;
  @Input() formState: FormState = FormState.NormalFirstoaded;
  @Input() ActiveOnly: boolean;
  @Input() HasLocation: boolean;
  @Input() LinkedToEmployeeIdList: number[];
  
  @Output() onProjectChanged = new EventEmitter<number | null>();

  dataSource$: Observable<ProjectForListDto[]>;
  allProjects$: Observable<ProjectForListDto[]>;
  
  get getValue() : number | null {
    return this.control ? this.control.value : this.value;
  }

  get isDisabled(): boolean {
    return this.disabled || this.control?.disabled;
  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if(changes.label){
      this.label = this.translate.instant(this.label);
    }

    if(changes.formState){
      setTimeout(() => {
        if(!this.control?.value) { this.clearDxValidators(); }
      }, 100);
    }

  }

  onOpened(e){
    this.dataSource$ = this.allProjects$;

    if(this.ActiveOnly)
      this.dataSource$ = this.dataSource$.pipe(map(arr => arr.filter(p => p.IsActive)));

    if(this.HasLocation)
      this.dataSource$ = this.dataSource$.pipe(map(arr => arr.filter(p => p.HasLocation)));

    if(this.LinkedToEmployeeIdList?.length)
      this.dataSource$ = this.dataSource$.pipe(map(arr => arr.filter(p => p.EmployeeProjectList.map(pe => pe.EmployeeId).find(empId => this.LinkedToEmployeeIdList.includes(empId)))));
  }

  onClosed(e){
    this.dataSource$ = this.allProjects$;
  }

  onValueChanged(e){
    if(this.control && e.event){
      this.control.markAsTouched();
      this.control.patchValue(e.value);
    }
    else 
    {
      this.onProjectChanged.emit(e.value);
    }
  }
  
  clearDxValidators() {
    this.validatorViewChildren?.toArray()?.forEach(ref => {
      ref.instance.reset();
    });
  }

  constructor
  (
    private projectClient: ProjectClient,
    private translate: TranslateService
  ) 
  {
    this.dataSource$ = this.allProjects$ = this.projectClient.getAllProjectsList();
    this.label = this.translate.instant(this.label);
  }

}
