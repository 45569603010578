
import { Injectable } from '@angular/core';
/**
 * @class CleanNull Service
 * @author muhammed.mousa@hadafsolutions.net (Muhammed Moussa)
 * @package accflexcloud
 * @version 1.0.0
 */

@Injectable({
    providedIn: 'root'
})

export class CleanNullService {
    cleanNull = obj => {
        for (const key in obj) {
          if (obj.hasOwnProperty(key) && obj[key] === null) {
            delete obj[key];
          }
        }
        return obj;
    }

    cleanEmptyString = obj => {
        for (const key in obj) {
          if (obj.hasOwnProperty(key) && obj[key] === '' || obj[key] === ' ') {
            delete obj[key];
          }
        }
        return obj;
    }

    cleanNullAndEmptyString = obj => {
        for (const key in obj) {
          if (obj.hasOwnProperty(key) && obj[key] === '' || obj[key] === ' ' || obj[key] === null) {
            delete obj[key];
          }
        }
        return obj;
    }

}
