import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';

/**
 * @class SafePipe
 * @author alaa.essam@hadafsolutions.net (Alaa Essam)
 * @package ESS
 * @version 1.1.2
 */

@Pipe({ name: 'safe'})
export class SafePipe implements PipeTransform {
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  constructor(private sanitizer: DomSanitizer) {}
}
