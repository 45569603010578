<div class="flex flex-row flex-wrap">

    <label class="w-full sm:w-full md:w-1/4 lg:w-1/4 xl:w-1/4 p-2">
        {{ label }}
        <span *ngIf="isRequired" class="required-astr">*</span>
    </label>
    <div class="w-full sm:w-full md:w-3/4 lg:w-3/4 xl:w-3/4">
        <dx-drop-down-box #dropdownRef 
            [rtlEnabled]="isRtlEnabled$ | async" 
            [value]="EmployeeNames"
            [showClearButton]="!isRequired"
            [disabled]="disabled" 
            [readOnly]="readOnly" 
            [dropDownOptions]="{ fullScreen: true }"
            (onValueChanged)="onDropDownValueChanged($event)"
            >
            <div *dxTemplate="let data of 'content'">
                <dx-button
                    icon="remove"
                    [hint]="'COMMON._Close_' | translate"
                    (onClick)="dropdownRef.instance.close()"
                ></dx-button>

                <dx-data-grid #gridRef
                    [rtlEnabled]="isRtlEnabled$ | async"
                    [dataSource]="Employees$ | async"
                    keyExpr="EmployeeId" 
                    [selectedRowKeys]="value"
                    [filterRow]="{ visible: true, showOperationChooser: false }"
                    [paging]="{ enabled: true, pageSize: 10 }" 
                    [hoverStateEnabled]="true"
                    [scrolling]="{ mode: 'virtual', rowRenderingMode: 'virtual' }" 
                    [showBorders]="false" 
                    height="100%"
                    [columnHidingEnabled]="true"
                    (onSelectionChanged)="onGridSelectionChanged($event)"
                    >
                    <dxo-selection selectAllMode="allPages" showCheckBoxesMode="always" [mode]="selection">
                    </dxo-selection>

                    <dxi-column dataField="EmployeeNumber" [caption]="'EMPLOYEE._Number_' | translate"></dxi-column>

                    <dxi-column dataField="EmployeeName" [caption]="'EMPLOYEE._EmployeeName_' | translate"></dxi-column>

                    <dxi-column dataField="EmployeeNameSecondLanguage"
                        [caption]="'EMPLOYEE._EmployeeNameSecondLanguage_' | translate"></dxi-column>

                    <dxi-column dataField="BranchId" [caption]="'COMMON._Branch_' | translate">
                        <dxo-lookup [dataSource]="Branch" displayExpr="BranchName" valueExpr="BranchId"></dxo-lookup>
                    </dxi-column>

                    <dxi-column dataField="DepartmentId" [caption]="'VACATIONS._Department_' | translate">
                        <dxo-lookup [dataSource]="Department" displayExpr="DepartmentName" valueExpr="DepartmentId">
                        </dxo-lookup>
                    </dxi-column>

                    <dxi-column dataField="DefaultProjectId" [caption]="'PROJECTATTENDANCE._Project_' | translate">
                        <dxo-lookup [dataSource]="Project" displayExpr="ProjectName" valueExpr="ProjectId"></dxo-lookup>
                    </dxi-column>

                    <dxi-column dataField="JobId" [caption]="'COMMON._Job_' | translate">
                        <dxo-lookup [dataSource]="Job" displayExpr="JobName" valueExpr="JobId"></dxo-lookup>
                    </dxi-column>

                    <dxi-column dataField="Mobile" [caption]="'COMMON._Mobile_' | translate"></dxi-column>

                    <dxi-column dataField="Telephone" [caption]="'COMMON._Telephone_' | translate"></dxi-column>
                  
                </dx-data-grid>
            </div>
        </dx-drop-down-box>
    </div>
</div>
