import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DxDropDownBoxComponent } from 'devextreme-angular/ui/drop-down-box';
import { Observable } from 'rxjs';
import { GlobalLanguageService } from 'src/app/core/services/global-language.service';
import { SubSink } from 'subsink';
import { PersonnelCorrespondenceClient, CorrespondenceStatusDto } from '../../payroll-client';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'correspondence-status-checklist',
  templateUrl: './correspondence-status-checklist.component.html'
})

export class CorrespondenceStatusChecklistComponent implements OnInit, OnDestroy {
  
  @ViewChild('gridRef') gridRef: DxDataGridComponent;
  @ViewChild('dropdownRef') dropdownRef: DxDropDownBoxComponent;

  @Input() value: number[] = [];
  @Input() selection: string;
  @Input() isRequired: boolean;
  @Input() disabled: boolean;
  @Input() readOnly: boolean;
  @Input() label: string;
  
  isRtlEnabled$: Observable<boolean>;
  StatusList$: Observable<CorrespondenceStatusDto[]>;

  @Output() OnDropDownSelectionChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() OnGridChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() OnValueChanged: EventEmitter<number> = new EventEmitter<number>();

  private subs = new SubSink();

  ngOnInit() {
    this.setRtl();
    this.getStatusList();
  }

  getStatusList = (): void => {
    this.showLoading();
    this.StatusList$ = this.correspondenceClient.getCorrespondenceStatus();
  }

  displayExpr = (item: any): any => item && item.Name;

  onDropDownSelectionChanged = (e: any): void => {
    this.OnDropDownSelectionChanged.emit(e);
  }

  onGridSelectionChanged = (e: any): void => {
    this.handleDropDownClose();
    this.OnGridChanged.emit(e);
  }

  onStatusValueChanged = (e) => {
    this.onshowClearButtonClicked(e);
    this.value = e.value;
    this.OnValueChanged.emit(e.value);
  }

  handleDropDownClose = (): void => {
    if (this.selection === 'single') {
      this.dropdownRef.instance.close();
      return;
    }
  }

  onshowClearButtonClicked = (e) => {
    if (!e.value) {
      this.gridRef.instance.deselectAll();
    }
  }

  showLoading = (): void => this.loadingHandlerService.showLoading();
  hideLoading = (): void => this.loadingHandlerService.hideLoading();

  handleError = (error: any) => this.errorHandlerService.handleError(error);

  setRtl(){
    this.isRtlEnabled$ = this.language.getLang();
  }

  ngOnDestroy() { this.subs.unsubscribe(); }

  constructor(
    private loadingHandlerService: LoadingService,
    private errorHandlerService: ErrorHandlerService,
    private language: GlobalLanguageService,
    private correspondenceClient: PersonnelCorrespondenceClient
  ) { }

}

