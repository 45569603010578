import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { configStorageGetter } from 'src/app/shared/utilities/config-storage-getter';
import { VersionCode } from 'src/app/models';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
/**
 * @class Version Code Service
 * @author alaa.essam@hadafsolutions.net (Alaa Essam)
 * @package ESS
 * @version 1.0.0
 */

@Injectable({
  providedIn: 'root'
})

export class VersionCodeService {
  apiUrl;
  private codeUrl = 'assets/configs/app-code.json';

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
    this.getApiUrl();
  }

  // tslint:disable: no-console
  private getApiUrl = async () => {
    try {
      this.apiUrl =  configStorageGetter()?.apiUrls?.apiUrl;
      console.info('API DONE WITH CONFIG FILE...');
    } catch (error) {
      console.info('FAILED TO GET CONFIG FILE FOR API...');
    }
  }

  public getModifiedVersionCode(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.codeUrl).subscribe((response: any) => {
        window.localStorage.setItem('android_version_code', JSON.stringify(response.android));
        window.localStorage.setItem('ios_version_code', JSON.stringify(response.ios));
        resolve(true);
      });
    });
  }

  getVersionCode = (): Observable<VersionCode> => {
    return this.http.get<VersionCode>(`${this.apiUrl}/PersonnelVersion`, {
      headers: {
        Authorization: 'Bearer ' + this.authService.getStoredAccessToken(),
        'Accept-Language': localStorage.getItem('SelectedLang') || 'ar-EG'
      }
    });
  }
}
