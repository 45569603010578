import { Component, Input, OnInit } from '@angular/core';
import { Notifications } from 'src/app/models';
import { CleanNullService } from '../../services/clean-null.service';
import { NotifactionsService } from '../../services/notifactions.service';
import { QuerySerializeService } from '../../services/query-serialize.service';
/**
 * @Component NotifactionComponent
 * @author alaa.essam@hadafsolutions.net (Alaa Essam)
 * @package ESS
 */

@Component({
  selector: 'app-notification',
  templateUrl: './notifaction.component.html',
  styleUrls: ['./notifaction.component.css']
})

export class NotifactionComponent implements OnInit{
    @Input() isRtlEnabled;
    @Input() notifiCounter: number;

    notifications: Notifications[];

    newNotification: boolean;
    isShowNotifcation = false;
    isPopupVisible = false;

    notifiTitle;
    notifyBody;
    checkedState = true;

  ngOnInit() {
    this.getNotificationCount();
  }

  showHideNotification = () => this.isShowNotifcation = !this.isShowNotifcation;
  hideNotification = () => this.isShowNotifcation = false;
  controlNotification = () => {
    this.showHideNotification();
    if (this.isShowNotifcation) {
      this.getNotification();
    }
  }

  onNotifiClick = (notifi) => {
    this.notifiTitle = notifi.Title;
    this.notifyBody = notifi.Message;
    this.isPopupVisible = true;
  }

  getNotification = () => {
    this.getAllNotification();
    this.getNotificationCount();
  }

  getAllNotification = () => {
    this.accflexNotifactionService.getNotification(null);
    this.notifications = this.accflexNotifactionService.Notification;
  }

  getUnReadNotification = async () => {

    await this.accflexNotifactionService.getNotification(this.handleQueryForNotifi(0));
    this.notifications = await this.accflexNotifactionService.Notification;
  }


  handleQueryForNotifi = (state: number): string => {
    let query = {
      state
   };
    query = this.clean.cleanNullAndEmptyString(query);
    return this.querySerializeService.serialize(query);
  }

  markAllAsRead = () => {
    this.accflexNotifactionService.markAllNotificationAsRead().subscribe(() => {
      this.getNotificationCount();
    });
  }
 
  getNotificationCount = () => {
    if (! window.localStorage.getItem('access_token')) { return; }
    this.accflexNotifactionService.getNotificationCount().subscribe(res => {
      this.notifiCounter = res;
    });
  }

  changeNotificationState = (e) => {
    if (e.detail.checked) {
      this.getAllNotification();
      return;
    }
    if (!e.detail.checked) {
      this.getUnReadNotification();
      return;
    }
  }
  constructor(
    private accflexNotifactionService: NotifactionsService,
    private querySerializeService: QuerySerializeService,
    private clean: CleanNullService
  ){}
}
