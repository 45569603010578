<span class="notifi-alert" *ngIf="notifiCounter">
    {{notifiCounter}}
</span>


<div class="notifi-container dark:bg-gray-800 bg-white" *ngIf="isShowNotifcation" [style]="isRtlEnabled? 'left: 5px;' : 'right: 5px'">
    <div class="flex flex-wrap justify-between">
        <p (click)="markAllAsRead()" class="text-blue-400 hover:text-blue-600 cursor-pointer px-2">
            <i class="far fa-envelope-open"></i> 
            {{isRtlEnabled? "قراءة الكل" : "mark all as read"}}
        </p>
        <div class="flex flex-wrap">
            <div class="text-green-400 hover:text-green-600">
                {{isRtlEnabled? "الكل" : "All"}}

            </div>
            <div>
                <ion-toggle class="pt-1 pb-2 px-2" color="success" checked="{{checkedState}}" (ionChange)="changeNotificationState($event)"></ion-toggle>

            </div>
            <div class="text-green-400 hover:text-green-600">
                {{isRtlEnabled? "غير مقروء" : "Un Read"}}

            </div>

        </div>


    </div>
    <div class="notifi-list">
        <div class="notifi-item flex flex-wrap cursor-pointer" *ngFor="let item of notifications" (click)="onNotifiClick(item)">
            <div class="w-1/4 text-center text-3xl" [class]="item.ReadState? 'text-gray-500' : 'font-bold'">
                <i class="far fa-envelope" *ngIf="!item.ReadState"></i>
                <i class="far fa-envelope-open" *ngIf="item.ReadState"></i>
            </div>
            <div class="w-3/4">
                <p class="notifi-text" [class]="item.ReadState? 'text-gray-500' : 'font-bold'">{{item.Title}}</p>
            </div>
        </div>
    </div>
</div>


<dx-popup
[title]="notifiTitle"
[visible]="isPopupVisible"
shading="true"
shadingColor="rgba(0, 0, 0, 0.2)"
closeOnBackButton="true"
closeOnOutsideClick="true"
height="auto"
width="auto"
[rtlEnabled]="isRtlEnabled"
resizeEnabled="true"
(onHiding)="isPopupVisible = false"
dragEnabled="true">
<div *dxTemplate="let data of 'content'" class="p-5">
  <h3> {{notifyBody}}</h3>
</div>
</dx-popup>