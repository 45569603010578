import { Component, Output, EventEmitter, Input } from '@angular/core';

/**
 * @class  BackBtn Component
 * @author muhammed.mousa@hadafsolutions.net (Muhammed Moussa)
 * @package personnel
 * @version 0.1.0
 */

@Component({
  selector: 'app-shared-back-btn',
  template: `
    <dx-button
      class="circle-btn bg-white dark:bg-gray-800 absolute"
      [rtlEnabled]="isRtlEnabled"
      [icon]="isRtlEnabled ? 'chevronright' : 'chevronleft'"
      [hint]="'COMMON._Back_' | translate"
      (onClick)="OnClickEmitter()"
    ></dx-button>
  `
})

export class SharedBackBtnComponent {
  @Input() isRtlEnabled: boolean;
  @Input() isSearch: boolean;
  @Input() OtherValidations?: boolean;

  @Output() OnClick: EventEmitter<any> = new EventEmitter<any>();

  OnClickEmitter = () => this.OnClick.emit();

  constructor() { }
}
