import { Device } from '@ionic-native/device/ngx';
import { Injectable } from '@angular/core';
// import { NativeStorage } from '@ionic-native/native-storage/ngx';

/**
 * @class StorageS ervice
 * @author muhammed.mousa@hadafsolutions.net (Muhammed Moussa)
 * @package ESS
 * @version 1.0.0
 */

@Injectable({
  providedIn: 'root'
})

export class StorageService {
  private _platform: string;

  public setItem(key: string, value: any) {
    // if (this._isAndroid() || this._isIOS()) {
    //   this.nativeStorage.setItem(key, value)
    //   .then(
    //     () => console.log(`Stored => ${key}`),
    //     error => console.error('Error storing item', error)
    //   );
    // } else {
      window.localStorage.setItem(key, value);
    // }
  }

  public getItem(key: string) {
    // if (this._isAndroid() || this._isIOS()) {
    //   try {
    //     const value = await this.nativeStorage.getItem(key);
    //     return value;
    //   } catch (err) {
    //     console.error('Error getting item', err);
    //     return err;
    //   }
    // } else {
      return window.localStorage.getItem(key);
    // }
  }

  public removeItem(key: string) {
    // if (this._isAndroid() || this._isIOS()) {
    //   try {
    //     const value = await this.nativeStorage.getItem(key);
    //     console.log(`Removed => ${key}`);
    //     return value;
    //   } catch (err) {
    //     console.error('Error Removing item', err);
    //     return err;
    //   }
    // } else {
      window.localStorage.removeItem(key);
    // }
  }

  public clear() {
    if (this._isAndroid() || this._isIOS()) {
      this.clearForAndroid();
    } else {
      window.localStorage.clear();
    }
  }

  public currentPlatform(): string {
    return this._platform;
  }
  public getPlatform(): any {
    return this._getPlatform();
  }

  public clearForAndroid() {
    window.localStorage.removeItem('access_token');
    window.localStorage.removeItem('currentUser');
    window.localStorage.removeItem('refresh_token');
    window.localStorage.removeItem('access_token_expiration');
    window.localStorage.removeItem('isTokenExpired');
  }

  private _getPlatform(): void {
    const platform = this.device.platform || 'false';
    this._platform = platform;
  }

  private _isAndroid(): boolean {
    return this._platform === 'Android';
  }

  private _isIOS(): boolean {
    return this._platform === 'Ios';
  }

  constructor(
    private device: Device,
    // private nativeStorage: NativeStorage
  ) {
    this._getPlatform();
  }
}
