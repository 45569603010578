import { UUID } from "angular2-uuid";
export interface Vacation {
    VacationId: number;
    EmployeeName: string;
    VacationTypeId: number;
    VacationTypeName: string;
    EmployeeDepName: number;
    DateFrom: string;
    DateTo: string;
    VacationDays: number;
    ManagerApproved: boolean;
    HrApproved: boolean;
    VacationStatusId: number;
    VacationStatusName: string;
    Notes: string;
    VacationBalance: number;
    AlternativeEmployeeName: string;
    AlternativeEmployeeId: number;
    HrApprovedStatus: number;
    ManagerApprovedStatus: number;
    ManagerRefuseReason: string;
    HrRefuseReason: string;
    GuidNumber: string;
}

export class IVacation implements Vacation {
    VacationId = null;
    EmployeeName = '';
    VacationTypeId = null;
    VacationTypeName = '';
    EmployeeDepName = null;
    DateFrom = new Date().toLocaleDateString();
    DateTo = new Date().toLocaleDateString();
    VacationDays = null;
    ManagerApproved = false;
    HrApproved = false;
    VacationStatusId = null;
    VacationStatusName = '';
    Notes = '';
    VacationBalance = null;
    AlternativeEmployeeName = '';
    AlternativeEmployeeId = null;
    HrApprovedStatus = 0;
    ManagerApprovedStatus = 0;
    ManagerRefuseReason = '';
    HrRefuseReason = '';
    GuidNumber = UUID.UUID();
}
