import { Injectable, Injector } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { Employee } from 'src/app/models';
import { configStorageGetter } from '../utilities/config-storage-getter';
import { ErrorHandlerService } from './error-handler.service';
import { LoadingService } from './loading.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})

export class UserDataService {
  currentUser$ = new ReplaySubject<Employee>(null);
  apiUrl;

  handleInitUser = (): void => {
    const currentUser = this.storageService.getItem('currentUser');
    if (currentUser) {
      this.currentUser$.next(JSON.parse(currentUser));
    } else {
      this.getCurrentUserData();
    }
  }

  getCurrentUserData = (): void => {
    this.getApiUrl();
    this.showLoading();
    this.http.get(`${this.apiUrl}/Employee/CurrentEmployee`).subscribe(res => {
      this.storageService.setItem('currentUser', JSON.stringify(res));
      this.currentUser$.next(res);
      this.hideLoading();
    }, error => {
      this.hideLoading();
      this.errorHandlerService.handleError(error);
    });
  }

  setCurrentUserData = (data: Employee) => {
    this.storageService.setItem('currentUser', JSON.stringify(data));
    this.currentUser$.next(data);
  }

  handleError = (error: any) => this.errorHandlerService.handleError(error);

  showLoading = (): void => this.loadingService.showLoading();
  hideLoading = (): void => this.loadingService.hideLoading();

  // tslint:disable: no-console
  private getApiUrl = async () => {
    try {
      this.apiUrl = configStorageGetter()?.apiUrls?.apiUrl;
      console.info('API DONE WITH CONFIG FILE...');
    } catch (error) {
      console.info('FAILED TO GET CONFIG FILE FOR API...');
    }
  }


  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService,
    private loadingService: LoadingService,
    private storageService: StorageService
  ) {}
}
