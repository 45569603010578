import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';

/**
 * @module Auth Guard
 * @author muhammed.mousa@hadafsolutions.net (Muhammed Moussa)
 * @package mobile/perconnel
 * @version 1.0.0
 */


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.authService.isAccessTokenExpired()) {
      this.authService.refreshToken();
    }
    if (!this.authService.hasAccessToken() || !this.authService.hasRefreshToken()) {
      this.router.navigateByUrl('/signin');
      return false;
    }
    if (!this.authService.isAccessTokenExpired()) {
      return true;
    } else {
      this.router.navigateByUrl('/signin');
      return false;
    }
  }

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}
}
