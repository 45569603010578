import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpEvent
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';

@Injectable()

export class LanguageInterceptor implements HttpInterceptor {
    constructor(
        private storageService: StorageService
    ){}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const Lang = this.storageService.getItem('SelectedLang') || 'ar-EG';
        if (!req.url.includes('accesscontrol') ) {
            const authReq = req.clone(
                {
                    setHeaders: {
                        'Accept-Language': Lang
                    }
                });
                return next.handle(authReq);
        }
        return next.handle(req);

    }
}
