import { Component, ViewChild } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { SubSink } from 'subsink';

import { AuthService } from './core/services/auth.service';
import { StorageService } from './shared/services/storage.service';
import { GlobalLanguageService } from './core/services/global-language.service';
import { LicenseModalPage } from './shared/pages/license-modal/license-modal.page';
import { LicenseService } from './shared/services/license.service';
import { NavComponent } from './core/components/nav/nav.component';
import { NetworkPopupComponent } from './shared/components/network-popup/network-popup.component';
import { VersionCodePopupComponent } from './shared/components/version-code-popup/version-code-popup.component';
import { LoadingService } from './shared/services/loading.service';
import { VersionCodeService } from './shared/services/version-code.service';
import { Observable } from 'rxjs';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';
import { BaseService } from './shared/services/base.service';
import config from 'devextreme/core/config';
/**
 * @Component AppComponent
 * @author muhammed.mousa@hadafsolutions.net (Muhammed Moussa)
 * @package ESS
 * @version 1.0.0
 */
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent {
  @ViewChild('appNavRef') appNavRef: NavComponent;
  @ViewChild('NetworkPopupComponentRef') NetworkPopupComponentRef: NetworkPopupComponent;
  @ViewChild('VersionCodePopupComponentRef') VersionCodePopupComponentRef: VersionCodePopupComponent;

  isRtlEnabled: boolean;
  isSignIn: boolean;
  testData: any;
  isLoading$: Observable<boolean> = this.loadingService.isLoading$;
  error$: Observable<any>;

  public onlineOffline: boolean = navigator.onLine;
  private subs = new SubSink();

  initializeApp() {
     this.platform.ready()
    .then(() => {
      this.statusBar.overlaysWebView(false);
      this.splashScreen.hide();
      this.listenConnection();
      this.refreshToken();
      this.getModifiedVersionCode();
      this.initTranslate();
      this.isSignInRoute();
      this.getTheme();
      this.getVersionCodeForAndroid();
      this.getVersionCodeForIos();
    })
    .then(() => {
      // @TODO: for debug
      // const Android = true;
      // if (Android) {
      if ( this.storageService.currentPlatform() === 'Android' || this.platform.is('ios')) {
        localStorage.setItem('configSettings', '');
        localStorage.setItem('mobApp', 'true');
        this.handleMobileUrls();
        return;
      } else {
        this.authService.confgAuthPasswordFlowConfigForWeb();
        localStorage.setItem('mobApp', 'false');
      }
    });
  }

  private handleMobileUrls() {
    if (!this.licenseService.isLicenseExist()) {
      if (this.platform.is('ios')) {
        this.showModal('ios');
      }
      if (this.platform.is('android')) {
        this.showModal('md');
      }
    } else {
      return this.authService.confgAuthPasswordFlowConfigForMobileCached(window.localStorage);
    }
  }

  async showModal(envMode) {
    const modal = await this.modalCtrl.create({
      component: LicenseModalPage,
      cssClass: 'p-5 text-center',
      componentProps: {
        companyCode: this.testData,
      },
      swipeToClose: false,
      backdropDismiss: false,
      mode: envMode
    });

    return await modal.present();
  }

  async dismissModal() {
    await this.modalCtrl.dismiss({
      component: LicenseModalPage,
    });
  }

  getTheme() {
    const theme = this.storageService.getItem('theme');
    if (theme === 'dark') {
      document.body.classList.toggle('dark', true);
    } else {
      document.body.classList.toggle('dark', false);
    }
  }

  getErrors = (): Observable<object> => this.error$ = this.errorHandlerService.error$;

  refreshToken = async () => {
    await this.authService.refreshToken();
  }

  private initTranslate = (): void => {
    this.globalLanguageService.getLang().subscribe(lang => {
      if (lang === null || lang === 'ar-EG' || this.translate.getBrowserLang() !== undefined) {
       this.translate.setDefaultLang('ar');
       this.isRtlEnabled = true;
      } else {
        this.translate.setDefaultLang('en');
        this.isRtlEnabled = false;
      }
    });
  }

  getVersionCodeForAndroid = () => {
    const isAndroid = this.storageService.currentPlatform() === 'Android';

    if (!isAndroid) {return; }
    this.subs.sink = this.versionCodeService.getVersionCode().subscribe(res => {
      if (!res.AndroidAppVersion) {return; }
      if (res.AndroidAppVersion === JSON.parse(this.storageService.getItem('android_version_code'))) { return; }
      if (res.AndroidAppVersion !== JSON.parse(this.storageService.getItem('android_version_code'))) {
        this.VersionCodePopupComponentRef.presentModal();
      }
    });
  }


  getVersionCodeForIos = () => {
    const isIos =  this.platform.is('ios');

    if (!isIos) {return; }
    this.subs.sink = this.versionCodeService.getVersionCode().subscribe(res => {
      if (!res.IosDeliveryAppVersion) {return; }
      if (res.IosDeliveryAppVersion === JSON.parse(this.storageService.getItem('ios_version_code'))) { return; }
      if (res.IosDeliveryAppVersion !== JSON.parse(this.storageService.getItem('ios_version_code'))) {
        this.VersionCodePopupComponentRef.presentModal();
      }
    });
  }

  isSignInRoute = () => {
    this.router.events.subscribe((e: any) => {
      if (e.url && e.url === '/signin') {
        this.isSignIn = true;
      }

      if (e.url && e.url !== '/signin') {
        this.isSignIn = false;
      }
    });
  }

  hideUserActionsList = () => {
    if (this.isSignIn) { return; }
    this.appNavRef.hideUserActionsList();
    this.appNavRef.hideNotificationList();
  }

  private listenConnection(): void {
    if (!navigator.onLine) {
      this.NetworkPopupComponentRef.handleNetowrkPopup();
    }
  }

  checkValidToken = () => {
    if (!this.authService.isAccessTokenExpired()) {
      this.router.navigateByUrl('');
    }
  }

  getModifiedVersionCode = () => this.versionCodeService.getModifiedVersionCode();

  checkOnlineState = () => {
    window.addEventListener('online', () => {
      if (!this.NetworkPopupComponentRef.isPopUpVisible) { return; }
      this.NetworkPopupComponentRef.dismiss();
      window.location.reload();
    });
  }

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateService,
    private authService: AuthService,
    private storageService: StorageService,
    private globalLanguageService: GlobalLanguageService,
    private licenseService: LicenseService,
    private router: Router,
    private loadingService: LoadingService,
    private versionCodeService: VersionCodeService,
    public modalCtrl: ModalController,
    private baseService: BaseService,
    private errorHandlerService: ErrorHandlerService
  ) {
    this.baseService.load();
    this.initializeApp();
    this.checkOnlineState();
    this.checkValidToken();
    this.getErrors();
  }
}
config({
  editorStylingMode: 'filled'
});
