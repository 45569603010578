import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { IOidcConfig, LicenseUrls } from 'src/app/models';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { configStorageGetter } from 'src/app/shared/utilities/config-storage-getter';
import { StorageService } from 'src/app/shared/services/storage.service';
import { LicenseService } from 'src/app/shared/services/license.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { NotifactionsService } from 'src/app/shared/services/notifactions.service';


/**
 * @class Auth Service
 * @author muhammed.mousa@hadafsolutions.net (Muhammed Moussa)
 * @package personnel
 * @version 1.0.0
 */

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  authPasswordFlowConfig;
  issuerApiUri;
  apiUrl;

  confgAuthPasswordFlowConfigForWeb = () => {
    this.getApiUrl();
    this.configureIssuer();
  }

  endsWith(str, suffix) {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
  }

  getIdentityURL = (): string => {
    let url = this.authPasswordFlowConfig.issuer;
    url = this.endsWith(url, '/') ? url : (`${url}/`);
    url = url.replace('connect/', '').replace('token/', '').replace('token', '');
    url = (`${url}connect/token`);

    return url;
  }

  refineApiUrls = (args) => {
    if (args.PersonnelUrl.endsWith('/')) {
      args.PersonnelUrl = args.PersonnelUrl.slice(0, -1);
    }

    if (args.MiscUrl && args.MiscUrl.endsWith('/')) {
      args.MiscUrl = args.MiscUrl.slice(0, -1);
    }

    if (args.IdentityUrl.endsWith('/')) {
      args.IdentityUrl = args.IdentityUrl.slice(0, -1);
    }
    this.checkForCorrectUrl(args);
    this.licenseService.storeLicenseUrls(args);
  }

  checkForCorrectUrl = (args) => {
    if (!args.PersonnelUrl.includes('api')) {
      args.PersonnelUrl = args.PersonnelUrl.concat('/api');
    }
    if (args.MiscUrl && !args.MiscUrl.includes('api')) {
      args.MiscUrl = args.MiscUrl.concat('/api');
    }
    return args;
  }

  confgAuthPasswordFlowConfigForMobile = (args: LicenseUrls, store: any): void => {
    this.refineApiUrls(args);

    const authPasswordFlowConfig = {
      ...this.authPasswordFlowConfig,
      issuer: args.IdentityUrl,
      clientId: 'personnel',
      scope: 'openid profile payrollapi IdentityServerApi offline_access miscapi'
    };

    const configSettings: IOidcConfig = {
      apiUrls: {
        issuerApiUri: `${args.IdentityUrl}/api`,
        apiUrl: args.PersonnelUrl,
        miscapi: args.MiscUrl
      },
      authPasswordFlowConfig
    };

    this.handleConfgUrls(configSettings);
  }

  confgAuthPasswordFlowConfigForMobileCached = (store: any): void => {
    this.refineApiUrls(this.licenseService.getstoredLicenseUrls());

    const authPasswordFlowConfig = {
      ...this.authPasswordFlowConfig,
      issuer: this.licenseService.getstoredLicenseUrls().IdentityUrl,
      clientId: 'personnel',
      scope: 'openid profile payrollapi IdentityServerApi offline_access miscapi'
    };

    const configSettings: IOidcConfig = {
      apiUrls: {
        issuerApiUri: `${this.licenseService.getstoredLicenseUrls().IdentityUrl}/api`,
        apiUrl: this.licenseService.getstoredLicenseUrls().PersonnelUrl,
        miscapi: this.licenseService.getstoredLicenseUrls().MiscUrl
      },
      authPasswordFlowConfig
    };
    this.handleConfgUrls(configSettings);
  }

  loginWithPassword = (username: string, password: string) => {
    this.showLoading();
    this.getAccessToken(username, password).toPromise().then(async (res: any) => {
      this.storageService.setItem('access_token', res.access_token);
      this.storageService.setItem('refresh_token', res.refresh_token);
      this.setAccessTokenExpiration(res);
    })
      .then(() => {
        this.getUserIdFromToken();
        this.router.navigateByUrl('/');
        this.hideLoading();
      })
      .catch(error => {
        this.hideLoading();
        this.errorHandlerService.handleError(error);
      });
  }

  logout = async () => {
    await this.removeDevice();
    this.errorHandlerService.setErrors(null);
    this.router.navigateByUrl('/signin');
    this.storageService.clear();
    window.location.reload();
  }

  getAccessToken = (username, password) => {
    const newToken = new HttpParams()
      .set('client_id', 'personnel')
      .set('grant_type', 'password')
      .set('scope', 'openid profile payrollapi IdentityServerApi offline_access miscapi')
      .set('username', username)
      .set('password', password)
      .set('client_secret', '702392EB311D4A12B9CAEEE0BF775E63');
    return this.http.post(this.getIdentityURL(), newToken.toString(),
      {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
      });
  }

  isAccessTokenExpired = (): boolean => {
    const token = this.storageService.getItem('access_token');
    const helper = new JwtHelperService();
    return helper.isTokenExpired(token);
  }

  setAccessTokenExpiration = (token) => {
    const helper = new JwtHelperService();
    this.storageService.setItem('access_token_expiration', helper.getTokenExpirationDate(token.access_token));
    this.storageService.setItem('isTokenExpired', helper.isTokenExpired(token.access_token));
  }

  refreshToken = async () => {
    if (!this.hasRefreshToken()) { return; }
    if (this.isAccessTokenExpired() || !this.getStoredAccessToken()) {
      this.showLoading();
      await this.getRefreshedToken().toPromise().then(res => {
        this.storageService.setItem('access_token', res.access_token);
        this.storageService.setItem('refresh_token', res.refresh_token);
        this.hideLoading();
        this.router.navigateByUrl('');
      });
    }
  }

  getRefreshedToken = (): Observable<any> => {
    this.configureIssuer();
    const newToken = new HttpParams()
      .set('client_id', 'personnel')
      .set('grant_type', 'refresh_token')
      .set('client_secret', '702392EB311D4A12B9CAEEE0BF775E63')
      .set('refresh_token', this.storageService.getItem('refresh_token'));
    return this.http.post(this.getIdentityURL(), newToken.toString(),
      {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
      });
  }

  hasAccessToken = (): boolean => {
    if (!this.getStoredAccessToken() || !this.getStoredAccessToken().length) {
      return false;
    } else {
      return true;
    }
  }

  getStoredAccessToken = (): string => this.storageService.getItem('access_token');

  hasRefreshToken = (): string => this.storageService.getItem('refresh_token');

  getUserIdFromToken = () => {
    const token = this.storageService.getItem('access_token');
    const helper = new JwtHelperService();
    return JSON.parse(helper.decodeToken(token).userId);
  }

  forgetPassowrd = () => window.location.href = (`${this.authPasswordFlowConfig.issuer}/Account/ForgotPassword`);
  resetPassword = (OldPassword: string, NewPassword: string): Observable<any> => {
    return this.http.post(`${this.issuerApiUri}/User/ChangePassword`, { OldPassword, NewPassword });
  }

  removeDevice = () => {
    const DeviceId = JSON.parse(this.storageService.getItem('currentDeviceToken'));
    const obj = {
      DeviceId
    };
    this.notifactionsService.removeCurrentDeviceId(obj);
  }

  handleConfgUrls = async (configSettings) => {
    window.localStorage.setItem('configSettings', JSON.stringify(configSettings));
    await this.getApiUrl();
    await this.configureIssuer();
  }

  configureIssuer = () => {
    const storedIssuerConfig = this.storageService.getItem('configSettings');
    if (!storedIssuerConfig) {
      window.location.reload();
    } else {
      this.authPasswordFlowConfig = JSON.parse(storedIssuerConfig).authPasswordFlowConfig;
    }
  }

  // tslint:disable: no-console
  private getApiUrl = async () => {
    try {
      this.authPasswordFlowConfig = configStorageGetter()?.authPasswordFlowConfig;
      this.issuerApiUri = configStorageGetter()?.apiUrls.issuerApiUri;
      this.apiUrl = configStorageGetter()?.apiUrls.apiUrl;
      console.info('AUTH API DONE WITH CONFIG FILE...');
    } catch (error) {
      console.info('FAILED TO GET CONFIG FILE FOR AUTH API...');
    }
  }

  showLoading = (): void => this.loadingService.showLoading();
  hideLoading = (): void => this.loadingService.hideLoading();

  constructor(
    private loadingService: LoadingService,
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService,
    private storageService: StorageService,
    private router: Router,
    private licenseService: LicenseService,
    private notifactionsService: NotifactionsService
  ) { }
}
