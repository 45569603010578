<dx-button
    [rtlEnabled]="isRtlEnabled"
    [disabled]="IsDisabled"
    text="{{
        !isSearch && isRtlEnabled ? 'حفظ' :
        !isSearch && !isRtlEnabled ? 'Save' :
        isSearch && isRtlEnabled ? 'بحـث' :
        isSearch && !isRtlEnabled ? 'Search' : ''}}"
    (onClick)="OnSubmitEmitter()"
    [width]="width"
    useSubmitBehavior="true"
    class="w-full hover:bg-green-400 my-2 save-btn transition duration-200 ease-in-out transform hover:-translate-y-.5"
></dx-button>
