import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import jwt_decode from 'jwt-decode';
import { StorageService } from 'src/app/shared/services/storage.service';
import { configStorageGetter } from 'src/app/shared/utilities/config-storage-getter';

@Injectable()

export class AttachmentsDataService {
  
  miscAPIUrl;
  issuerApiUri;
  tenantIdentifier;

  endsWith(str, suffix) {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
  }

  getIdentityURL = (): string => {
    let url = this.miscAPIUrl;
    url = this.endsWith(url, '/') ? url : (`${url}/`);
    url = url.replace('api/', '');

    return url;
  }

  getAddAttachmentURL(entityName: string, entityGuid: string) {
    return `${this.getIdentityURL()}api/EntityAttachment?entityName=${entityName}&entityGuid=${entityGuid}&tenantIdentifier=${this.tenantIdentifier}`;
  }


  getViewAttachmentSrc = (entityName: string, entityGuid: string): string  => {
    return `${this.getIdentityURL()}api/ViewEntityAttachment?entityName=${entityName}&entityGuid=${entityGuid}&tenantIdentifier=${this.tenantIdentifier}&output=embed`;
  }

  private getApiUrl = async () => {
    try {
      this.miscAPIUrl =  configStorageGetter()?.apiUrls?.miscapi;
      this.issuerApiUri =  configStorageGetter()?.apiUrls?.issuerApiUri;
    } catch (error) {
      console.info('FAILED TO GET CONFIG FILE FOR API...');
    }
  }

  decodeToken = () => {
    const token = this.storageService.getItem('access_token');
    const decoded: any = jwt_decode(token);
    this.tenantIdentifier = decoded.Identifier;
  }
  
  constructor(
    private http: HttpClient,
    private storageService: StorageService,
  ) {
    this.getApiUrl();
    this.decodeToken();
  }
}


