import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './core/components/login/login.component';
import { NewPasswordComponent } from './core/components/new-password/new-password.component';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'signin',
    component: LoginComponent
  },
  {
    path: 'new-password',
    component: NewPasswordComponent
  },
  {
    // @TODO: UPDATE ID
    path: 'advance',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/advance/advance.module').then(m => m.AdvancePageModule),
    data: { ModuleId: 272 }
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    data: { ModuleId: 272 },
    loadChildren: () => import('./modules/employee/employee.module').then( m => m.EmployeeModule)
  },
  {
    path: 'salary-detail',
    canActivate: [AuthGuard],
    data: { ModuleId: 272 },
    loadChildren: () => import('./modules/salary-detail/salary-detail.module').then( m => m.SalaryDetailModule)
  },
  {
    path: 'attendance',
    canActivate: [AuthGuard],
    data: { ModuleId: 272 },
    loadChildren: () => import('./modules/attendance/attendance.module').then( m => m.AttendancePageModule)
  },
  {
    path: 'kpi',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/kpi/kpi.module').then( m => m.KpiPageModule)
  },
  {
    path: 'vacation',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/vacation/vacation.module').then( m => m.VacationPageModule)
  },
  {
    path: 'approval-pool',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/approval-pool/approval-pool.module').then( m => m.ApprovalPoolPageModule)
  },
  {
    path: 'license-modal',
    loadChildren: () => import('./shared/pages/license-modal/license-modal.module').then( m => m.LicenseModalPageModule)
  },
  {
    path: 'resignation',
    loadChildren: () => import('./modules/resignation/resignation.module').then( m => m.ResignationModule)
  },
  {
    path: 'effective-date-notice',
    loadChildren: () => import('./modules/effective-date-notice/effective-date-notice.module').then( m => m.EffectiveDateNoticeModule)
  },
  {
    path: 'project-attendance',
    loadChildren: () => import('./modules/project-attendance/project-attendance.module').then( m => m.ProjectAttendanceModule)
  },
  {
    path: 'correspondences',
    loadChildren: () => import('./modules/correspondences/correspondences.module').then( m => m.CorrespondenceModule)
  },
  {
    path: 'leave-permission',
    loadChildren: () => import('./modules/leave-permission/leave-permission.module').then( m => m.LeavePermissionModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
