import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class QuerySerializeService {
  serialize = (data) => {
    if (typeof (data) === 'string') { return data; }
    const query = [];
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        query.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]));
      }
    }
    return query.join('&');
  }

}
