import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorHandlerService } from '../../services/error-handler.service';

/**
 * @class  ErrorL ist Component
 * @author muhammed.mousa@hadafsolutions.net (Muhammed Moussa)
 * @package mobile/perconnel
 * @version 1.0.0
 */

@Component({
  selector: 'app-error-list',
  template: `
    <div *ngIf="error$ | async as error" class="error-list">
      <ul>
        <li *ngFor="let err of error | keyvalue"> {{ err.value }}</li>
      </ul>
    </div>
  `,
  styleUrls: ['./error-list.component.sass']
})

export class ErrorListComponent {
  @Input() error$: Observable<object>;
}
