import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LicenseModalPage } from './license-modal.page';

/**
 * @Module LicenseModalPageRouting Module
 * @author muhammed.mousa@hadafsolutions.net (Muhammed Moussa)
 * @package ESS 
 * @version 1.0.0
 */

const routes: Routes = [
  {
    path: '',
    component: LicenseModalPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LicenseModalPageRoutingModule {}
