<ion-header>  
  <ion-toolbar color="light">  
    <ion-title size="large">Company Code</ion-title>  
  </ion-toolbar>  
</ion-header>   

<ion-content class="modal-bg rounded">  
  <ion-item class="text-center">
    <ion-label position="stacked">Please enter the company code</ion-label>
    <ion-input clearInput [(ngModel)]="companyCode" placeholder="####"></ion-input>
  </ion-item>
  <ion-button expand="block" (click)="getLicenseByCode()" class="shadow-lg w-2/3 mx-auto my-3 h-8 text-base btn-bg">  
    Verify
    <ion-ripple-effect type="unbounded"></ion-ripple-effect>
  </ion-button>  
  <img src="/assets/dist/img/ver-code.png" class="w-2/3 m-auto">
</ion-content> 