import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { SharedToasterService } from 'src/app/shared/services/shared-toaster.service';
import { AuthService } from '../../services/auth.service';


@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.sass']
})
export class NewPasswordComponent implements OnInit, AfterViewInit {
  NewPasswordForm: FormGroup;
  stylingMode = 'filled';
  loginFailed: boolean;
  error$: Observable<object>;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private sharedToasterService: SharedToasterService,
    private translate: TranslateService,
    private errorHandlerService: ErrorHandlerService,
    private loadingService: LoadingService
  ) { }

  ngOnInit() {
    this.renderForm();
    this.getErrors();
  }

  ngAfterViewInit(): void {
    // this.drowIconsInsideInputs();
  }

  renderForm = (): void => {
    this.NewPasswordForm = this.fb.group({
      OldPassword: ['', Validators.required],
      NewPassword: ['', Validators.required],
      NewPasswordConfirm: ['', Validators.required]
    });
  }

  getErrors = (): Observable<object> => this.error$ = this.errorHandlerService.error$;

  drowIconsInsideInputs = (): void => {
    const oldpasswordbox = document.querySelector('.OldPassNameBox').children[0].children[0];
    const newpasswordbox = document.querySelector('.NewPassNameBox').children[0].children[0];
    const newpasswordconfirmbox = document.querySelector('.ConfirmNewPassNameBox').children[0].children[0];

    // // @TODO: HANDLE cstm-icon STYLE FROM SASS...
    const passwordicon = document.createElement('I');
    passwordicon.className = 'cstm-icon fas fa-user';
    passwordicon.style.margin = '0 10px';
    passwordicon.style.color = '#fff';
    passwordicon.style.fontWeight = 'bold';

    oldpasswordbox.insertAdjacentElement('afterbegin', passwordicon);
    newpasswordbox.insertAdjacentElement('afterbegin', passwordicon);
    newpasswordconfirmbox.insertAdjacentElement('afterbegin', passwordicon);
  }

  onSubmit = async (e: any) => {
    const { value : { NewPassword, NewPasswordConfirm} } = this.NewPasswordForm;
    if (
      this.NewPasswordForm.valid &&
      NewPassword === NewPasswordConfirm
    ) {
      await this.authService.resetPassword(e.OldPassword, e.NewPassword)
      .subscribe(() => {
        location.replace('/');
      }, error => {
        this.hideLoading();
        this.errorHandlerService.handleError(error);
      });
    } else {
      await this.handleValidation(e);
      return;
    }
  }

  handleValidation = (values: any): void  => {
    const error: any = { };

    if (!values.OldPassword) {
      error.OldPassword = this.translate.instant('LOGIN._OldPasswordRequired_');
    }

    if (!values.NewPassword) {
      error.NewPassword = this.translate.instant('LOGIN._NewPasswordRequired_');
    }

    if (!values.NewPasswordConfirm) {
      error.NewPasswordConfirm = this.translate.instant('LOGIN._NewPasswordConfirmRequired_');
    }

    if (
      values.NewPassword
      && values.NewPasswordConfirm
        && values.NewPassword !== values.NewPasswordConfirm) {
      error.NewPasswordConfirm = this.translate.instant('LOGIN._NotMatchPassword_');
    }

    this.errorHandlerService.setErrors(error);
  }

  showLoading = (): void => this.loadingService.showLoading();
  hideLoading = (): void => this.loadingService.hideLoading();

}
