import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

/**
 * @class  ResetBtn Component
 * @author muhammed.mousa@hadafsolutions.net (Muhammed Moussa)
 * @package personnel
 * @version 0.1.0
 */

@Component({
  selector: 'app-shared-reset-btn',
  templateUrl: './shared-reset-btn.component.html'
})

export class SharedResetBtnComponent {
  @Input() IsDisabled: FormGroup;
  @Input() isRtlEnabled: boolean;
  @Input() OtherValidations?: boolean;
  @Input() text:string = this.translate.instant('COMMON._Cancel_');

  @Output() OnReset: EventEmitter<any> = new EventEmitter<any>();
  OnResetEmitter = () => this.OnReset.emit();

  constructor(
    private translate: TranslateService,
  ) { }
}

