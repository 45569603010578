import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IOidcConfig } from 'src/app/models';

/**
 * @Service BaseService
 * @author muhammed.mousa@hadafsolutions.net (Muhammed Moussa)
 * @package accflexerp
 * @version 1.1.2
 */

@Injectable()

export class BaseService {
  private envUrl = 'assets/configs/config.json';
  private configSettings: IOidcConfig = null;
  public apiUrl = null;

  get settings() {
    return this.configSettings;
  }

  public load(): Promise<any> {
    return new Promise((resolve, reject) => {
      const http =  this.inej.get(HttpClient);
      if (window.localStorage.getItem('configSettings')?.length) {return; }
      http.get(this.envUrl).subscribe((response: any) => {
        window.localStorage.setItem('configSettings', JSON.stringify(response));
        this.configSettings = response;
        resolve(true);
      });
    });
  }

  constructor(public inej: Injector) {}
}
