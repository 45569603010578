import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

/**
 * @class  SaveBtn Component
 * @author muhammed.mousa@hadafsolutions.net (Muhammed Moussa)
 * @package personnel
 * @version 0.1.0
 */

@Component({
  selector: 'app-shared-save-btn',
  templateUrl: './shared-save-btn.component.html'
})

export class SharedSaveBtnComponent implements OnInit {
  @Input() IsDisabled: FormGroup;
  @Input() isRtlEnabled: boolean;
  @Input() isSearch = false;
  @Input() OtherValidations?: boolean;
  @Input() width?: any;

  @Output() OnSubmit: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() { }

  OnSubmitEmitter = () => this.OnSubmit.emit();

  constructor() { }
}
