<div class="login-container w-full flex text-center p-2">
    <div class="login-form w-full sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
        <div class="w-full flex justify-between">
            <dx-drop-down-button
                [text]="'COMMON._Language_' | translate"
                [dropDownOptions]="{ width: 100 }"
                [items]="language"
                displayExpr="name"
                keyExpr="value"
                (onItemClick)="switchLanguage($event)"
            ></dx-drop-down-button>
            <div (click)="clearstoredLicenseUrls()" class="mx-5 cust-btn">
                <label>{{'COMMON._ClearLicense_' | translate}}</label>
            </div>
        </div>
        <div>
            <img src="/assets/dist/img/logo.png" alt="Logo" width="190">
            <span> {{'COMMON._SignIn_' | translate}} </span>
        </div>

        <form [formGroup]="LoginForm" autocomplete="on">
            <div class="flex centrize flex-wrap mb-4">
                <label class="w-full sm:w-full md:w-auto lg:w-auto xl:w-auto mb-2 lg:mx-2 xl:mx-2"> {{'COMMON._UserName_'| translate}}</label>
                <div class="w-full sm:w-full md:w-full lg:w-8/12 xl:w-8/12">
                    <dx-text-box
                        [stylingMode]="stylingMode"
                        formControlName="UserName"
                        mode="email"
                        (onEnterKey)="onSubmit(LoginForm.value)"
                        [elementAttr]="{ class: 'UserNameBox' }"
                        [validationStatus]="(error$ | async) && (error$ | async)?.UserName ? 'invalid' : 'valid'"
                        email
                    ></dx-text-box>
                </div>
            </div>

            <div class="flex centrize flex-wrap">
                <label class="w-full sm:w-full md:w-auto lg:w-auto xl:w-auto mb-2 lg:mx-2 xl:mx-2"> {{'COMMON._Password_'| translate}}</label>
                <div class="w-full sm:w-full md:w-full lg:w-8/12 xl:w-8/12">
                <dx-text-box
                    #passowrdTextRef
                    [stylingMode]="stylingMode"
                    formControlName="Password"
                    [elementAttr]="{ class: 'PasswordBox' }"
                    mode="password"
                    (onEnterKey)="onSubmit(LoginForm.value)"
                    [validationStatus]="(error$ | async) && (error$ | async)?.Password ? 'invalid' : 'valid'"
                ></dx-text-box>
                <div (click)="ShowPassword()" class="w-full flex show-pass">
                    <span><img src="/assets/dist/img/eye.png" class="m-1"></span> 
                    <span>{{'COMMON._ShowPassword_'| translate}}</span>  
                </div>
                </div>

        </div>

            <dx-button
                [text]="'COMMON._SignIn_' | translate"
                style="background: #0443c1; height: 50px; color:#fff"
                (onClick)="onSubmit(LoginForm.value)"
            >
            </dx-button>

            <p class="text-lg text-blue-500 font-semibold cursor-pointer hover:text-green-500 hover:underline" (click)="onForgetPassowrd()">{{'COMMON._ForgetPassword_' | translate}}</p>
            <p class="text-sm text-blue-500 font-semibold cursor-pointer hover:text-green-500 hover:underline" (click)="goToPrivacyAndPolicy()">{{'COMMON._PrivacyAndPolicy_' | translate}}</p>
        </form>
    </div>
</div>
