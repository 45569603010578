import { AnimationController } from '@ionic/angular';
import { AnimationOptions } from '../models/TransitionOptions';
const animationCtrl = new AnimationController();

export const getIonPageElement = (element: HTMLElement) => {
  if (element.classList.contains('ion-page')) {
    return element;
  }

  const ionPage = element.querySelector('.ion-page');
  if (ionPage) {
    return ionPage;
  }
  // idk, return the original element so at least something animates and we don't have a null pointer
  return element;
};


export function pageTransition(_: HTMLElement, opts: AnimationOptions) {

    // root animation with common setup for the whole transition
    const rootTransition = animationCtrl.create()
      .duration(opts.duration || 350)
      .easing('cubic-bezier(0.3,0,0.66,1)');

    // ensure that the entering page is visible from the start of the transition
    const enteringPage = animationCtrl.create()
      .addElement(getIonPageElement(opts.enteringEl))
      .beforeRemoveClass('ion-page-hidden');

    // create animation for the leaving page
    const leavingPage = animationCtrl.create().addElement(
      getIonPageElement(opts.leavingEl)
    );

    // actual customized animation
    if (opts.direction === 'forward') {
      enteringPage.fromTo('transform', 'translateX(100%)', 'translateX(0)');
      leavingPage.fromTo('opacity', '1', '0.25');
    } else {
      leavingPage.fromTo('transform', 'translateX(0)', 'translateX(100%)');
      enteringPage.fromTo('opacity', '0.25', '1');
    }

    // include animations for both pages into the root animation
    rootTransition.addAnimation(enteringPage);
    rootTransition.addAnimation(leavingPage);
    return rootTransition;
  }
