import { Injectable } from '@angular/core';
import { LicenseUrls } from 'src/app/models';

/**
 * @class License Service
 * @author muhammed.mousa@hadafsolutions.net (Muhammed Moussa)
 * @package ESS 
 * @version 1.0.0
 */

@Injectable({
  providedIn: 'root'
})

export class LicenseService {
    /**
     * isLicenseExist
     */
    public isLicenseExist() {
        const storedLicense = localStorage.getItem('isLicenseExist') || false;
        return storedLicense;
    }

    /**
     * confirmLicenseExist
     */
    public confirmLicenseExist() {
        localStorage.setItem('isLicenseExist', 'true');
    }

    /**
     * storeLicenseUrls
     */
    public storeLicenseUrls(urls: LicenseUrls) {
        localStorage.setItem('PersonnelUrl', urls.PersonnelUrl);
        localStorage.setItem('IdentityUrl', urls.IdentityUrl);
        localStorage.setItem('MiscUrl', urls.MiscUrl);
    }

    /**
     * getstoredLicenseUrls
     */
    public getstoredLicenseUrls(): LicenseUrls {
        const PersonnelUrl = localStorage.getItem('PersonnelUrl');
        const IdentityUrl = localStorage.getItem('IdentityUrl');
        const MiscUrl = localStorage.getItem('MiscUrl');

        return {
            PersonnelUrl,
            IdentityUrl,
            MiscUrl
        }
    }

    /**
     * clearstoredLicenseUrls
     */
    public clearstoredLicenseUrls() {
        localStorage.removeItem('PersonnelUrl');
        localStorage.removeItem('IdentityUrl');
        localStorage.removeItem('MiscUrl');
        localStorage.removeItem('isLicenseExist');
        window.location.reload();
    }

    /**
     * getLicensUrlseByCode
     */
    public async getLicensUrlseByCode(code: string): Promise<LicenseUrls> {
        const url = 'https://newlicense.hadafsolutions.net/api/Server?code=';
        return new Promise(async (resolve, reject) => {
            try {
                const res = await fetch(`${url}${code}`);
                const json = await res.json();
                resolve(json)
            } catch (err) {
                reject(err)
            }
        });
    }
}
