import { Device } from '@ionic-native/device/ngx';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AddAuthorizationHeaderInterceptor } from './shared/interceptors/add-authorization-header-interceptor';
import { LanguageInterceptor } from './shared/interceptors/language-interceptor.interceptor';
import { DxLoadPanelModule } from 'devextreme-angular/ui/load-panel';
import { I18LoaderService } from './core/services/i18n-loader.service';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { LicenseModalPageModule } from './shared/pages/license-modal/license-modal.module';
import { Market } from '@ionic-native/market/ngx';
import { PAYROLL_API_BASE_URL } from './shared/payroll-client';
import { configStorageGetter } from './shared/utilities/config-storage-getter';
import { DateHandlerInterceptor } from './shared/interceptors/date-handler-interceptor';
import { pageTransition } from './animations/navAnimation';

// import { NgxElectronModule } from 'ngx-electron';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ animated: true, navAnimation: pageTransition }),
    HttpClientModule,
    DxLoadPanelModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    // NgxElectronModule, @TODO: USE FOR BUILD ELECTORN DESKTOP
    LicenseModalPageModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: I18LoaderService
      },
    }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Device,
    Market,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddAuthorizationHeaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DateHandlerInterceptor,
      multi: true
    },
    { provide: PAYROLL_API_BASE_URL,
      useFactory: getPayrollApiUrl 
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

function getPayrollApiUrl(): string {
  return configStorageGetter()?.apiUrls?.apiUrl?.replace('/api', '');
}


