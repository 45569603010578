import { StorageService } from './../../../shared/services/storage.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LicenseService } from 'src/app/shared/services/license.service';
import { DxTextBoxComponent } from 'devextreme-angular/ui/text-box';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})

export class LoginComponent implements OnInit {
  @ViewChild('passowrdTextRef') passowrdTextRef: DxTextBoxComponent;
  stylingMode = 'filled';
  loginFailed: boolean;
  language: any[];
  error$: Observable<any>;

  LoginForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private errorHandlerService: ErrorHandlerService,
    private storageService: StorageService,
    private translate: TranslateService,
    private licenseService: LicenseService
  ) { }

  ngOnInit() {
    this.renderForm();
    this.getErrors();
    this.setLanguageList();
  }

  setLanguageList = () => {
    this.language =  [
      { value: 'ar-EG', name: 'العربية' },
      { value: 'en-US', name: 'English' },
    ];
  }

  getErrors = (): Observable<object> => this.error$ = this.errorHandlerService.error$;

  renderForm = (): void => {
    this.LoginForm = this.fb.group({
      UserName: ['', Validators.required],
      Password: ['', Validators.required]
    });
  }

  ShowPassword = () => {
    const password = this.passowrdTextRef.mode;
    if (password === 'password') {
      this.passowrdTextRef.mode = 'text';
    }
    if (password !== 'password') {
      this.passowrdTextRef.mode = 'password';
    }
  }

  onSubmit = async (e: any) => {
    if (this.LoginForm.valid) {
      await this.authService.loginWithPassword(
        e.UserName,
        e.Password
      );
    } else {
      await this.handleValidation(e);
      return;
    }
  }

  onForgetPassowrd = () => this.authService.forgetPassowrd();
  goToPrivacyAndPolicy = () => window.location.href = (`https://accflex.com/ESS-Privacy`);

  handleValidation = (values: any): void  => {
    const error: any = { };

    if (!values.UserName) {
      error.UserName = this.translate.instant('LOGIN._UsernemRequired_');
    }

    if (!values.Password) {
      error.Password = this.translate.instant('LOGIN._PasswordRequired_');
    }

    if (values.UserName && !this.errorHandlerService.isValidEmail(values.UserName)) {
      error.UserName = this.translate.instant('LOGIN._NotValidMail_');
    }

    this.errorHandlerService.setErrors(error);
    this.errorHandlerService.timeOutToClearError();
  }

  switchLanguage = (e) => {
    this.storageService.setItem('SelectedLang', e.itemData.value);
    location.reload();
  }

  clearstoredLicenseUrls = () => this.licenseService.clearstoredLicenseUrls();
}
