import { ButtonComponent } from './components/button/button.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BaseService } from './services/base.service';
import { ErrorHandlerService } from './services/error-handler.service';
import { SharedToasterService } from './services/shared-toaster.service';
import { ConfirmPopupComponent } from './components/confirm-popup/confirm-popup.component';
import { ErrorListComponent } from './components/error-list/error-list.component';
import { LoadingComponent } from './components/loading/loading.component';
import { SharedBackBtnComponent } from './components/shared-back-btn/shared-back-btn.component';
import { SharedResetBtnComponent } from './components/shared-reset-btn/shared-reset-btn.component';
import { SharedSaveBtnComponent } from './components/shared-save-btn/shared-save-btn.component';
import { SharedToggleComponent } from './components/shared-toggle/shared-toggle.component';
import { SuccesPopupComponent } from './components/succes-popup/succes-popup.component';
import { IonicModule } from '@ionic/angular';
import { NavComponent } from '../core/components/nav/nav.component';
import { BtnAddComponent } from './components/btn-add/btn-add.component';
import { ErrorPopupComponent } from './components/error-popup/error-popup.component';
import { NetworkPopupComponent } from './components/network-popup/network-popup.component';
import { VersionCodeService } from './services/version-code.service';
import { VersionCodePopupComponent } from './components/version-code-popup/version-code-popup.component';
import { SafePipe } from './Pipe/safe.pipe';
import { NotifactionComponent } from './components/notifaction/notifaction.component';
import { AttachmentsDataService } from '../core/services/attachments-data.service';
import { BrowserTab } from '@ionic-native/browser-tab/ngx';
import { AttachmentPopupComponent } from './components/attachment-popup/attachment-popup.component';
import { GoogleMapComponent } from './components/google-map/google-map.component';
import { BranchClient, CompanyAppSettingsClient, DepartmentClient, EmployeeClient, JobClient, PersonnelWorkShiftClient, ProjectClient } from './payroll-client';
import { ShiftsLookupComponent } from './components/shifts-lookup/shifts-lookup.component';
import { ProjectsLookupComponent } from './components/projects-lookup/projects-lookup.component';
import { SharedEmployeeListComponent } from './components/shared-employee-list/shared-employee-list.component';
import { CorrespondenceStatusChecklistComponent } from './components/correspondence-status-checklist/correspondence-status-checklist.component';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxRadioGroupModule } from 'devextreme-angular/ui/radio-group';
import { DxLookupModule } from 'devextreme-angular/ui/lookup';
import { DxNumberBoxModule } from 'devextreme-angular/ui/number-box';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { DxTextAreaModule } from 'devextreme-angular/ui/text-area';
import { DxCheckBoxModule } from 'devextreme-angular/ui/check-box';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxLoadPanelModule } from 'devextreme-angular/ui/load-panel';
import { DxToastModule } from 'devextreme-angular/ui/toast';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { DxDropDownButtonModule } from 'devextreme-angular/ui/drop-down-button';
import { DxAccordionModule } from 'devextreme-angular/ui/accordion';
import { DxChartModule } from 'devextreme-angular/ui/chart';
import { DxSwitchModule } from 'devextreme-angular/ui/switch';
import { DxTemplateModule } from 'devextreme-angular/core';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxDropDownBoxModule } from 'devextreme-angular/ui/drop-down-box';

/**
 * @class Shared Module
 * @author muhammed.mousa@hadafsolutions.net (Muhammed Moussa)
 * @package personnel
 * @version 1.0.0
 */

const DX_MODULES = [
    DxButtonModule,
    DxDataGridModule,
    DxRadioGroupModule,
    DxLookupModule,
    DxNumberBoxModule,
    DxDateBoxModule,
    DxTextAreaModule,
    DxCheckBoxModule,
    DxFormModule,
    DxPopupModule,
    DxTextBoxModule,
    DxLoadPanelModule,
    DxToastModule,
    DxTabPanelModule,
    DxTemplateModule,
    DxDropDownButtonModule,
    DxAccordionModule,
    DxChartModule,
    DxSwitchModule,
    DxScrollViewModule,
    DxDropDownBoxModule
];

const SHARED_PROVIDERS = [
  BaseService,
  ErrorHandlerService,
  SharedToasterService,
  VersionCodeService,
  AttachmentsDataService,
  BrowserTab,
  PersonnelWorkShiftClient,
  ProjectClient,
  CompanyAppSettingsClient,
  EmployeeClient,
  DepartmentClient,
  JobClient,
  BranchClient
];

const SHARED_COMPONENTS = [
  SharedSaveBtnComponent,
  SharedResetBtnComponent,
  SharedToggleComponent,
  ConfirmPopupComponent,
  LoadingComponent,
  ErrorListComponent,
  SharedBackBtnComponent,
  // RouterItemComponent,
  SuccesPopupComponent,
  ButtonComponent,
  NavComponent,
  BtnAddComponent,
  ErrorPopupComponent,
  NetworkPopupComponent,
  VersionCodePopupComponent,
  NotifactionComponent,
  AttachmentPopupComponent,
  GoogleMapComponent,
  ShiftsLookupComponent,
  ProjectsLookupComponent,
  SharedEmployeeListComponent,
  CorrespondenceStatusChecklistComponent
];

const SHARED_MODULES = [
  CommonModule,
  ReactiveFormsModule,
  TranslateModule,
  FormsModule,
  IonicModule
];
@NgModule({
  declarations: [
    ...SHARED_COMPONENTS,
    SafePipe
  ],
  imports: [
    ...SHARED_MODULES,
    ...DX_MODULES,
    RouterModule,
    // TranslateModule.forChild({
    //   loader: {
    //       provide: TranslateLoader,
    //       useClass: I18LoaderService
    //   },
    // })
  ],
  exports: [
    ...DX_MODULES,
    ...SHARED_COMPONENTS,
    ...SHARED_MODULES,
    SafePipe

  ],
  providers: [
    ...SHARED_PROVIDERS
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class SharedModule { }
