import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-loading',
  template: `
    <dx-load-panel
      shadingColor="rgba(0,0,0,0.4)"
      [visible]="isLoading$ | async"
      [showIndicator]="true"
      container="body"
      [showPane]="false"
      [shading]="true"
      message=" "
      [closeOnOutsideClick]="false"
    ></dx-load-panel>
  `,
  styles: ['']
})

export class LoadingComponent implements OnInit{
  isLoading$: Observable<boolean> = this.loadingHandlerService.isLoading$;

  ngOnInit() {
    this.isLoading$ = this.loadingHandlerService.isLoading$;
  }

  constructor(
    private loadingHandlerService: LoadingService
  ) { }
}
