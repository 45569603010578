import { Component, Input} from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-error-popup',
  templateUrl: './app-error-popup.html'
})
export class ErrorPopupComponent {
  @Input() isRtlEnabled: boolean;

  async presentModal() {
    const modal = await this.modalController.create({
      component: ErrorPopupComponent,
      cssClass: 'p-5 text-center error-popup',
    });
    return await modal.present();
  }

  public dismiss() {
    this.modalController.dismiss();
  }

  constructor(
    public modalController: ModalController
  ) {}
}
