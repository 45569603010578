import { Component, Input} from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-network-popup',
  template: `
  <ion-content class="modal-bg rounded bg-white p-5">
    <img src="/assets/dist/img/no-connection.png" alt="save-img" class="m-auto my-5 w-1/4">
    <h1 class="text-red-500 text-2xl" translate="COMMON._NoInternet_"></h1>
  </ion-content>`
})
export class NetworkPopupComponent {
  @Input() isRtlEnabled: boolean;
  isPopUpVisible = false;

  async presentModal() {
    const modal = await this.modalControllerNetowrk.create({
      component: NetworkPopupComponent,
      cssClass: 'p-5 text-center error-popup',
    });
    this.isPopUpVisible = true;
    return await modal.present();
  }

  public dismiss() {
    this.modalControllerNetowrk.dismiss();
    this.isPopUpVisible = false;
  }

  handleNetowrkPopup = () => this.presentModal();

  constructor(public modalControllerNetowrk: ModalController) {}
}
