import { NgModule } from '@angular/core';
import { LicenseModalPageRoutingModule } from './license-modal-routing.module';

import { LicenseModalPage } from './license-modal.page';
import { SharedModule } from '../../shared.module';

/**
 * @Module LicenseModalPage Module
 * @author muhammed.mousa@hadafsolutions.net (Muhammed Moussa)
 * @package ESS
 * @version 1.0.0
 */

@NgModule({
  imports: [
    SharedModule,
    LicenseModalPageRoutingModule
  ],
  declarations: [LicenseModalPage]
})
export class LicenseModalPageModule {}
