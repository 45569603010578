import { Component, Input} from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-succes-popup',
  template: `
  <ion-content class="modal-bg rounded bg-white p-5">
    <img src="/assets/dist/img/save.jpg" alt="save-img" class="m-auto my-5">
    <h1 *ngIf="isSuccessPopupMsg" translate="COMMON._SaveDone_" class="text-green-500 text-xl"></h1>
    <h1 *ngIf="!isSuccessPopupMsg" translate="COMMON._PassowrdUpdated_" class="text-green-500 text-xl"></h1>
  </ion-content>`
})
export class SuccesPopupComponent {
  @Input() isRtlEnabled: boolean;
  isSuccessPopupMsg: boolean;

  async presentModal() {
    const modal = await this.modalController.create({
      component: SuccesPopupComponent,
      cssClass: 'p-5 text-center error-popup',
    });
    return await modal.present();
  }

  public dismiss() {
    this.modalController.dismiss();
  }

  handleSuccessPopup = () => {
    this.isSuccessPopupMsg = true;
    this.presentModal();
    setTimeout(() => {
      this.dismiss();
    }, 3500);
  }

  handleSuccessPassowrdPopup = () => {
    this.isSuccessPopupMsg = false;
    this.presentModal();
    setTimeout(() => {
      this.dismiss();
    }, 3500);
  }

  constructor(
    public modalController: ModalController
  ) {}
}
