import { Injectable } from '@angular/core';
import notify from 'devextreme/ui/notify';

@Injectable()
export class SharedToasterService {
  showErrorToast = (body?: string) => notify(body, 'error', 1000);
  showSuccessToast = (body?: string) => notify(body, 'success', 1000);
  showInfoToast = (body?: string) => notify(body, 'info', 2000);
  showWarningToast = (body?: string) => notify(body, 'warning', 1000);
}
