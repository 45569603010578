<ion-app class="bg">
  <app-nav *ngIf="!isSignIn" [class.dx-ltr]="!isRtlEnabled" class="z-10" #appNavRef></app-nav>

  <ion-router-outlet [class.layout-mt]="!isSignIn" id="main-cont" (click)="hideUserActionsList()"></ion-router-outlet>

  <app-network-popup #NetworkPopupComponentRef></app-network-popup>
  <app-version-code-popup 
    #VersionCodePopupComponentRef
    [isRtlEnabled]="isRtlEnabled"
  ></app-version-code-popup>

  <app-loading></app-loading>
  <app-error-list [error$]="error$"></app-error-list>
</ion-app>