import { Injectable, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * @class Add Authorization Header Interceptor
 * @author muhammed.mousa@hadafsolutions.net (Muhammed Moussa)
 * @package personnel
 * @version 1.0.0
 */

@Injectable({
  providedIn: 'root'
})

export class LoadingService {
  isLoading$ = new BehaviorSubject<boolean>(false);

  showLoading = (): void => this.isLoading$.next(true);
  hideLoading = (): void => this.isLoading$.next(false);
}
