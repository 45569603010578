<!-- <nav class="main-nav bg-transparent">
    <div class="profile-nav w-1/3">
        <div>
            <dx-drop-down-button
                [text]="'COMMON._Language_' | translate"
                [dropDownOptions]="{ width: 100 }"
                [items]="language"
                displayExpr="name"
                [showArrowIcon]="false"
                class="bg-white dark:bg-transparent text-blue-500 dark:text-gray-100"
                keyExpr="value"
                (onItemClick)="switchLanguage($event)"
            ></dx-drop-down-button>
        </div>
        <div class="mx-0 lg:mx-2 sm:mx-0"><app-notification #notifactionRef [isRtlEnabled]="isRtlEnabled"></app-notification></div>
    </div>
    <div class="common-btns w-1/3 text-center">
        <app-btn-add></app-btn-add>
    </div>
    <div class="actions-btns w-1/3">
        <div class="user-img" (click)="showUserActionsList()">
            <img id="userImg" onerror="this.src='/assets/dist/img/Profile_placeholder.png';" src="{{'data:image/png;base64,'+(currentUser$ | async)?.PerImage}}" alt="user-img">
        </div>
    </div>
</nav>

<div class="user_actions_list" (click)="hideUserActionsList()" *ngIf="isUserActionsListVisible">
    <ul>
        <li (click)="logOut()">
            <span class="logout-btn text-blue-500 dark:text-gray-100 cursor-pointer mx-1">
                <ion-icon name="exit-outline"></ion-icon>
            </span>
            <label>{{'COMMON._LogOut_' | translate}}</label>
        </li>
        <li (click)="clearstoredLicenseUrls()">
            <span class="logout-btn text-blue-500 dark:text-gray-100 cursor-pointer mx-1">
                <ion-icon name="refresh-circle"></ion-icon>
            </span>
            <label>{{'COMMON._ClearLicense_' | translate}}</label>
        </li>
        <li class="pt-1 mx-1">
            <div *ngIf="darkModeOn" (click)="switchTheme('light')">
                <span class="logout-btn text-yellow-500 mx-1"><ion-icon name="sunny"></ion-icon></span>
                <label>{{'COMMON._ChangeTheme_' | translate}}</label>
            </div>
            <div *ngIf="!darkModeOn" (click)="switchTheme('dark')">
                <span  class="logout-btn text-gray-400 mx-1"><ion-icon name="moon"></ion-icon></span>
                <label>{{'COMMON._ChangeTheme_' | translate}}</label>
            </div>
        </li>
    </ul>
</div> -->

<ion-header>
    <ion-toolbar>
      <ion-buttons slot="secondary">

        <ion-button [routerLink]="['/']">
            <ion-icon slot="icon-only" name="home"></ion-icon>
        </ion-button>

        <ion-button [routerLink]="['/profile']">
            <ion-icon slot="icon-only" name="person-circle"></ion-icon>
          </ion-button>

        <ion-button (click)="controlNotification()">
            <ion-icon slot="icon-only" name="notifications"></ion-icon>
        </ion-button>
      </ion-buttons>
      
      <ion-buttons slot="primary">
        <ion-button color="secondary" (click)="showUserActionsList()">
          <ion-icon slot="icon-only" ios="ellipsis-horizontal" md="ellipsis-vertical"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>
          <img src="../../../../assets/dist/img/logo.png" width="60">
      </ion-title>
    </ion-toolbar>

  </ion-header>

  <div class="user_actions_list" (click)="hideUserActionsList()" *ngIf="isUserActionsListVisible">
    <ul>
        <li (click)="logOut()">
            <span class="logout-btn text-blue-500 dark:text-gray-100 cursor-pointer mx-1">
                <ion-icon name="exit-outline"></ion-icon>
            </span>
            <label>{{'COMMON._LogOut_' | translate}}</label>
        </li>
        <li (click)="clearstoredLicenseUrls()">
            <span class="logout-btn text-blue-500 dark:text-gray-100 cursor-pointer mx-1">
                <ion-icon name="refresh-circle"></ion-icon>
            </span>
            <label>{{'COMMON._ClearLicense_' | translate}}</label>
        </li>
        <li class="pt-1 mx-1">
            <div *ngIf="darkModeOn" (click)="switchTheme('light')">
                <span class="logout-btn text-yellow-500 mx-1"><ion-icon name="sunny" color="warning"></ion-icon></span>
                <label>{{'COMMON._ChangeTheme_' | translate}}</label>
            </div>
            <div *ngIf="!darkModeOn" (click)="switchTheme('dark')">
                <span  class="logout-btn text-gray-400 mx-1"><ion-icon name="moon" color="dark"></ion-icon></span>
                <label>{{'COMMON._ChangeTheme_' | translate}}</label>
            </div>
        </li>
    </ul>
</div>
<div class="absolute bottom-0">
    <dx-drop-down-button
        [text]="'COMMON._Language_' | translate"
        [dropDownOptions]="{ width: 100 }"
        [items]="language"
        displayExpr="name"
        [showArrowIcon]="false"
        class="bg-transparent text-blue-500 dark:text-gray-100"
        keyExpr="value"
        (onItemClick)="switchLanguage($event)"
    ></dx-drop-down-button>
</div>

<app-notification #notifactionRef [isRtlEnabled]="isRtlEnabled"></app-notification>