import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { OAuthModule } from 'angular-oauth2-oidc';

import { SharedModule } from '../shared/shared.module';
import { AppRoutingModule } from '../app-routing.module';
import { LoginComponent } from './components/login/login.component';
import { NewPasswordComponent } from './components/new-password/new-password.component';

/**
 * @class Core Module
 * @author muhammed.mousa@hadafsolutions.net (Muhammed Moussa)
 * @package personnel
 * @version 1.0.0
 */

const CORE_COMONENTS = [
  LoginComponent
];

@NgModule({
  declarations: [
    ...CORE_COMONENTS,
    NewPasswordComponent
  ],
  imports: [
    SharedModule,
    AppRoutingModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: ['http://www.angular.at/api'],
        sendAccessToken: true
      }
    })
  ],
  exports: [
    ...CORE_COMONENTS,
    OAuthModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CoreModule { }
